import { ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { RequiredPickMap } from '@/ui/typings/helpers'

import { LoadingProps } from './Loading'

const ANIMATION_DURATION = '1.4s'

type LoadingContainerProps = RequiredPickMap<LoadingProps, 'variant' | 'size'>

const colorVariation = (theme: ThemeProps) => ({
  secondary: theme.colors.green.primary,
  primary: theme.colors.purple.primary,
  light: theme.colors.white,
})

const sizeScale = {
  small: '1.2rem',
  medium: '1.6rem',
}

export const LoadingContainer = styled.div<LoadingContainerProps>(
  ({ theme, $variant = 'primary', $size = 'small' }) => `
  & {
    display: flex;
    flex-direction: row;
  }

  & div {
    animation: scaling ${ANIMATION_DURATION} ease-in-out infinite;
    background-color: ${colorVariation(theme)[$variant]};
    border-radius: ${theme.borderRadius.rounded};
    display: inline-block;
    height: ${sizeScale[$size]};
    margin: 0 calc(${sizeScale[$size]} / 3);
    width:  ${sizeScale[$size]};

    &:nth-child(0) {
      animation-delay: 0s;
    }

    &:nth-child(1) {
      animation-delay: -320ms;
    }

    &:nth-child(2) {
      animation-delay: -160ms;
    }
  }

  @keyframes scaling {
    0%,
    100% {
      transform: scale(0.5);
    }
    40% {
      transform: scale(1);
    }
    50% {
      transform: scale(1);
    }
  }
`
)
