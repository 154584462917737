import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPackageCost: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#373373">
        <path d="M9.924 4.288a.753.753 0 00-.37.226.89.89 0 00-.214.598c0 .542.36.739.777.908.435.163.515.276.515.437a.358.358 0 01-.395.397c-.297 0-.455-.164-.459-.478v-.13h-.52v.13c0 .48.261.825.673.934v.487a.257.257 0 00.258.257.26.26 0 00.264-.248v-.468a.86.86 0 00.438-.217.898.898 0 00.255-.665c0-.486-.289-.694-.818-.906-.377-.17-.475-.235-.475-.445 0-.161.056-.359.327-.359.226 0 .352.137.36.386v.13h.513v-.133c-.007-.45-.24-.758-.615-.85v-.467a.258.258 0 00-.514.001z" />
        <path d="M17.784 10.192l-1.7-3.148a.257.257 0 00-.15-.212l-2.013-.9V1.275a1.172 1.172 0 00-.468-.938 1.228 1.228 0 00-1.08-.21l-5.4 1.443a1.2 1.2 0 00-.898 1.149v.29l-1.178.34a1.15 1.15 0 00-.814 1.108v1.486l-1.986.885a.207.207 0 00-.044.026h-.006a.223.223 0 00-.035.033v.006a.222.222 0 00-.028.04L.217 10.192a.257.257 0 00.148.367l1.574.497v4.58a.258.258 0 00.192.249l6.748 1.785c.038.019.079.03.12.03h.027c.042 0 .083-.011.12-.03l6.749-1.785a.259.259 0 00.192-.249v-4.589l1.546-.488a.258.258 0 00.15-.367zm-15.33 1.026l4.58 1.445a.286.286 0 00.078.012.26.26 0 00.22-.133l1.405-2.512v7.07L2.45 15.437zM13.135.745a.664.664 0 01.266.53v6.192l-.84.239a5.24 5.24 0 00.324-1.555c.12-2.113-1-3.899-2.488-3.979-.739-.042-1.45.334-2.001 1.054-.53.691-.851 1.626-.906 2.632a4.844 4.844 0 00.54 2.579l-1.44-.402V2.719a.683.683 0 01.514-.652l5.4-1.444a.746.746 0 01.635.122zm-4.583 7.55c-.398-.657-.59-1.516-.54-2.42.051-.905.334-1.739.8-2.343.425-.554.95-.855 1.486-.855h.078c1.2.066 2.1 1.608 2.001 3.438a4.573 4.573 0 01-.445 1.766l-3.038.843h-.017a2.712 2.712 0 01-.325-.43zm-2.475-.416L4.6 7.468V4.457a.63.63 0 01.44-.614l1.037-.299zm-3.75-.51L8.507 9.09c.035.037.071.074.108.11l-1.627 2.908-6.17-1.947zm6.927 9.738V10.03l1.405 2.512a.258.258 0 00.303.121l4.605-1.453v4.227zm1.753-4.998l-1.66-2.977 6.327-1.763 1.508 2.793zm4.056-5.108l-1.141.319v-.825zm-10.979.323L2.955 7.01l1.128-.504z" />
      </g>
    </Icon>
  )
}
export default SvgPackageCost
