import { z } from "zod";
import config from "../next-seo.config";
import { DefaultSeoProps } from 'next-seo';

const DefaultSeoSchema = z.object({
  titleLong: z.string(),
  title: z.string(),
  description: z.string(),
  image: z.string(),
  titleTemplate: z.string(),
});

type defaultSeo = z.infer<typeof DefaultSeoSchema>;

/**
 * Generates default SEO configuration for the application.
 *
 * @param {defaultSeo} params - The SEO parameters.
 * @param {string} params.titleLong - The full title of the page.
 * @param {string} params.titleTemplate - The template for the page title.
 * @param {string} params.title - The short title for OpenGraph.
 * @param {string} params.description - The description of the page.
 * @param {string} params.image - The URL of the image to be used for OpenGraph.
 * @returns {DefaultSeoProps} The default SEO configuration object.
 */
export const defaultSeo = ({ titleLong, titleTemplate, title, description, image }: defaultSeo): DefaultSeoProps => ({
  ...config,
  title: titleLong,
  titleTemplate,
  openGraph: {
    title,
    description,
  },
  additionalMetaTags: [
    {
      property: 'og:image:secure_url',
      content: image,
    },
  ],
})
