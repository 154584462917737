import * as Accordion from '@radix-ui/react-accordion'
import styled from 'styled-components'

import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

import { AccordionProps } from './Accordion'

type StyledAccordionProps = Pick<AccordionProps, 'gap'>

export const StyledAccordion = styled(Accordion.Root)<StyledAccordionProps>(
  ({ gap }) => `
  display: flex;
  flex-direction: column;
  gap: ${gap}rem;
  width: 100%;
`
)

type StyledAccordionButtonProps = {
  $hasDescription?: boolean
}

export const StyledAccordionItem = styled(Accordion.Item)(
  ({ theme }) => `
  background: ${theme.colors.white};

  & > div[role="button"] {
    cursor: pointer;
  }
 
  &[data-disabled] p {
    color: ${theme.colors.grey.light2};
  }

  &[data-state='open'] {
    ${AccordionDescription} {
      display: none;
    }
  }

   &[data-disabled] {
    ${AccordionDescription} {
      display: block;
    }
  }

  &[data-state='closed'] {
    ${AccordionDescription} {
      display: block;
    }
`
)

export const StyledAccordionButton = styled(
  Accordion.Trigger
)<StyledAccordionButtonProps>(
  ({ theme, $hasDescription }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${theme.spacing.default};
  padding-bottom: ${$hasDescription && theme.spacing.defaultSmall};
  width: 100%;
  
  &[data-disabled] {
    cursor: not-allowed;
  }
`
)

export const StyledAccordionPanel = styled(Accordion.Content)(
  ({ theme }) => `
  margin-top: ${theme.spacing.default};
  padding: 0 ${theme.spacing.default} ${theme.spacing.default} ${theme.spacing.default};

  &[data-disabled] {
    display: none;
  }
`
)

export const AccordionDescription = styled.div(
  ({ theme }) => `
  ${styleFromTypography(theme.typography.body.small)}
  color: ${theme.colors.grey.light1};
  text-align: left;
`
)

export const RotatingChevronContainer = styled.div(
  ({ theme }) => `
  height: ${theme.spacing.default};
  width: ${theme.spacing.default};
`
)
