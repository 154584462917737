import _isNull from 'lodash/isNull'
import _isUndefined from 'lodash/isUndefined'

import { CookieRegistry, UpCookieRegistry } from '@/constants/cookieRegistry'
import { createCookie } from '@/utils/cookies/createCookie'
import { deleteCookie } from '@/utils/cookies/deleteCookie'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'
import { getCookieSubDomain } from '@/utils/cookies/getCookieSubDomain'
import { sanitizeCookies } from '@/utils/cookies/sanitizeCookies'
import { isServer } from '@/utils/isSSR'
import { sendToLogger } from '@/utils/sendToLogger'

/**
 * Latest search data is stored in the 'ls' (latest search) key of the 'up' (user preferences) cookie. Search data includes values such as the location ID ('l'), latitude ('lat'), SRP sort value ('sf') etc. The cookie is URI encoded.
 *
 * For example:
 * {
 *  ln: '168188167',
 *  ls: 'l=1700006&address=Richmond+Hill%2C+ON&lat=43.88284&lon=-79.440281&r=20&sv=LIST&sf=dateDesc'
 * }
 *
 * NOTE: Do not change the cookie format. The existing format is compatible with our legacy application.
 * */
export type LatestSearchCookie = {
  address?: string
  l?: string /** LocationId */
  lat?: string
  lon?: string
  r?: string /** Location radius in "km" */
  sf?: string /** SRP sort value */
  sv?: string /** SRP page view (i.e. LIST) */
}

/**
 * Splits cookie value string into an object
 * */
export const cookieStringToObject = (cookieString: string): LatestSearchCookie =>
  cookieString.split('&').reduce((acc, curr) => {
    const [key, value] = curr.split('=')
    return { ...acc, [key]: value }
  }, {})

/**
 * Transforms cookie value object into a string separating key/value pairs by "&" char
 * */
export const cookieObjectToString = (latestSearchCookieObject: LatestSearchCookie): string => {
  return Object.entries(latestSearchCookieObject).reduce((acc, [key, value]) => {
    if (_isUndefined(value) || _isNull(value)) return acc
    let param = acc.length === 0 ? '' : '&'
    param += `${key}=${value}`
    return `${acc}${param}`
  }, '')
}

/**
 * Get the user preference cookie
 * */
export const getUserPreferenceCookie = () =>
  getCookieByKey(document.cookie, CookieRegistry.USER_PREFERENCES)

/**
 * The cookie address is URI encoded
 * This function is responsible for decoding it and replacing the '+' chars with a whitespace ' '
 * */
export const decodeLocationCookieAddress = (address: string) =>
  decodeURIComponent(address).replace(/\+/g, ' ')

/**
 * Get latest search values from the user preferences (up) cookie.
 * Location cookie values shouldn't be used directly by NWA.
 * NWA should use the location reactive var.
 * */
export const getLatestSearchCookieValues = (
  userPreferenceCookies?: string
): LatestSearchCookie | null => {
  /** Prevents the function from running on the server-side */
  if (isServer() && !userPreferenceCookies) return null

  const cookie = userPreferenceCookies || getUserPreferenceCookie()
  if (!cookie) return null

  try {
    const decoded = decodeURIComponent(cookie)
    const sanitized = sanitizeCookies(decoded)
    const deserialized = JSON.parse(sanitized)

    /**
     * Get the latest search key from the user preferences cookie.
     * Cookie values are formatted as strings, and must be transformed into objects.
     * The resulting object will use the legacy application cookie format.
     * The latest search location value (l, lat, long) should be saved as a reactive var to be used in NWA.
     *  */
    const latestSearchString = deserialized[UpCookieRegistry.LATEST_SEARCH]
    const latestSearchObj: LatestSearchCookie = cookieStringToObject(latestSearchString)

    return latestSearchObj
  } catch (e) {
    /** If there is a problem with the UP cookie, clear it */
    if (!isServer()) {
      const hostname = global.location.hostname
      const domain = getCookieSubDomain(hostname)
      deleteCookie(CookieRegistry.USER_PREFERENCES, domain)
    }

    sendToLogger(e, {
      tags: { fn: 'getLatestSearchCookieValues' },
      fingerprint: ['JsonParse'],
      extra: { value: `cookie: ${cookie}` },
    })

    return null
  }
}

const parseLatestSearchCookies = (decodedCookie: string) => {
  try {
    const sanitizedCookie = sanitizeCookies(decodedCookie)
    const parsedCookie = JSON.parse(sanitizedCookie)
    return parsedCookie
  } catch (e) {
    sendToLogger(e, {
      tags: { fn: 'parseLatestSearchCookies' },
      fingerprint: ['JsonParse'],
      extra: { value: `preferences-cookies: ${decodedCookie}` },
    })
    return {}
  }
}

const removeEmptyKeys = (obj: Record<string, string>) => {
  const response: Record<string, string> = {}

  Object.keys(obj).forEach((key) => {
    if (obj[key]) response[key] = obj[key]
  })

  return response
}

/**
 * Function to update the latest search value in the user preferences cookie
 */
export const updateLatestSearchCookie = (value: LatestSearchCookie): void => {
  /** Prevents the function from running on the server-side */
  if (isServer()) return

  const cookie = getUserPreferenceCookie()
  let deserialized

  /**
   * It should either update or create a cookie based on the values received
   */
  if (cookie) {
    const decoded = decodeURIComponent(cookie)
    deserialized = parseLatestSearchCookies(decoded)

    const latestSearchString = deserialized[UpCookieRegistry.LATEST_SEARCH]

    const latestSearchObj = cookieStringToObject(latestSearchString)

    /**
     * Update values on cookies Obj
     * Then transform it back into a string before saving in the cookie
     *  */
    const updatedLatestSearchObj = removeEmptyKeys({ ...latestSearchObj, ...value })

    const updatedLatestSearchString = cookieObjectToString(updatedLatestSearchObj)

    /** Update existing cookie */
    deserialized[UpCookieRegistry.LATEST_SEARCH] = updatedLatestSearchString
  } else {
    const updatedLatestSearchObj = value
    const updatedLatestSearchString = cookieObjectToString(updatedLatestSearchObj)

    /** Create new cookie */
    deserialized = { [UpCookieRegistry.LATEST_SEARCH]: updatedLatestSearchString }
  }

  const serialized = JSON.stringify(deserialized)
  /** Encode serialized string before saving as a cookie*/
  const encoded = encodeURIComponent(serialized)
  createCookie(CookieRegistry.USER_PREFERENCES, encoded, { expiryInDays: 180 })
}
