export const getCookieSubDomain = (domain?: string) => {
  if (!domain) return ''

  if (domain.endsWith('a.run.app')) return domain

  const domainArray = domain.replace('www.', '').split(':')[0].split('.')

  if (domainArray.at(-2) === 'kijiji') return '.kijiji.ca' // If Production domain, always set subdomain cookie to .kijiji.ca (supports beta.kijiji.ca)
  if (domainArray[0] === 'localhost') return 'localhost'

  return `.${domainArray.splice(Math.max(0, domainArray.length - 3)).join('.')}`
}
