import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPriceTag: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#373373">
        <path d="M8.694 12.849a.164.164 0 00-.021-.018c.497-.593.48-1.303-.067-1.848-.515-.514-1.053-.412-1.926-.025-.66.268-.992.382-1.348.025s-.36-.78 0-1.133.82-.36 1.2.01l.022.021.36-.36-.022-.02c-.533-.516-1.154-.563-1.702-.144l-.01-.012-.67-.67a.258.258 0 00-.36.36l.67.67.018.013c-.328.46-.393 1.094.15 1.638s1.041.442 1.868.088c.76-.36 1.115-.38 1.408-.088.382.384.372.87-.03 1.27-.459.462-.987.466-1.446.015l-.022-.02-.36.36.022.021a1.477 1.477 0 001.034.468 1.44 1.44 0 00.84-.295.2.2 0 00.03.035l.67.67a.258.258 0 00.36-.36z" />
        <path d="M17.559.563c-1.072-1.129-3.51-.495-5.551 1.44a8.482 8.482 0 00-1.53 1.92H7.505a1.114 1.114 0 00-.793.328L.755 10.23a1.116 1.116 0 000 1.575l5.476 5.491a1.112 1.112 0 001.579 0l5.95-5.977c.208-.209.326-.492.326-.787V7.324a8.595 8.595 0 001.745-1.291c2.04-1.938 2.8-4.34 1.728-5.47zm-3.99 9.975a.6.6 0 01-.174.422L7.44 16.934a.6.6 0 01-.848 0L1.121 11.44a.6.6 0 010-.84l5.96-5.986a.6.6 0 01.424-.176h2.709a4.125 4.125 0 00-.417 1.708 1.514 1.514 0 10.523-.226 4.184 4.184 0 01.47-1.482h2.174a.6.6 0 01.6.6zm-3.292-3.065c.296.31.682.52 1.103.6a1 1 0 01-1.5-1.32c.072.269.208.516.397.72zm1.345.127a.26.26 0 00-.043-.013 1.627 1.627 0 01-.926-.467 1.263 1.263 0 01-.312-.672A1 1 0 0111.622 7.6zm3.849-1.942a8.232 8.232 0 01-1.389 1.07V5.034a1.117 1.117 0 00-1.116-1.114H11.09a8.099 8.099 0 011.273-1.541C14.133.699 16.342.03 17.186.919c.843.887.06 3.06-1.715 4.74z" />
      </g>
    </Icon>
  )
}
export default SvgPriceTag
