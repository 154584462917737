export const IcoBegin = 'GetOfferBegin'
export const IcoComplete = 'onIcoComplete'
export const ValueEstimateComplete = 'onValueEstimateComplete'
export const ValueEstimateBegin = 'ValueEstimateBegin'

export const IcoBeginProvince = 'icoBeginProvince'
export const IcoBeginVin = 'icoBeginVin'
export const IcoVinHelp = 'icoVinHelp'
export const IcoBeginKilo = 'icoBeginKilo'
export const IcoGetStartedAttempt = 'icoGetStartedAttempt'
export const IcoGetStartedCancel = 'icoGetStartedCancel'
export const IcoGetStartedSuccess = 'icoGetStartedSuccess'
export const IcoGetStartedFail = 'icoGetStartedFail'
export const IcoGetYourOffer = 'icoGetYourOffer'
export const IcoPostAdBegin = 'icoPostAdBegin'
export const IcoBOS = 'icoBOS'
export const IcoFaq = 'icoFaq'

export const PmrBeginProvince = 'pmrBeginProvince'
export const PmrBeginVin = 'pmrBeginVin'
export const PmrVinHelp = 'pmrVinHelp'
export const PmrBeginKilo = 'pmrBeginKilo'
export const PmrGetValueAttempt = 'pmrGetValueAttempt'
export const PmrGetValueCancel = 'pmrGetValueCancel'
export const PmrGetValueSuccess = 'pmrGetValueSuccess'
export const PmrGetValueFailure = 'pmrGetValueFailure'
export const PmrGetICO = 'pmrGetICO'
export const PmrPostAdBegin = 'pmrPostAdBegin'
export const PmrBOS = 'pmrBOS'
export const PmrFaq = 'pmrFaq'

export const LandingPageEventCategory = 'LandingPageCategory'

export const getAutoCanadaAppLabel = () => 'pltfrm=App'
export const getAutoCanadaWebLabel = () => 'pltfrm=Web'
