/**
 * System Messages are url query parameter driven messages, often used in redirects.
 * To add a new global system message, just add your message object to the SYSTEM_MESSAGES object below
 * and it will be automatically picked up by the global-messages bucket in GlobalMessages component.
 *
 * Using the keys currently used in legacy systems (ua, uar, uli, ula...), though not ideal,
 * gives us the safety of these messages display properly while we transition from legacy to new.
 */

import { type GaEvent } from '@/lib/ga/constants/gaEvent'
import { type SystemMessageProps } from '@/ui/molecules/system-message'

type SystemMessageDictionary = Record<string, SystemMessage>
type MessageType = Pick<SystemMessageProps, 'variation'>

export type SystemMessage = {
  key: string
  titleKey: string
  descriptionKey?: string
  links?: string[]
  gaEvent?: GaEvent
} & MessageType

const getGenericError = (key: string): SystemMessage => ({
  key,
  titleKey: 'system_messages.generic_error.title',
  descriptionKey: 'system_messages.generic_error.description',
  variation: 'error',
  links: ['system_messages.generic_error.help_link'],
})

export const CIS_ERROR_MESSAGES: SystemMessageDictionary = {
  // activation link token expired
  expired_jwt: {
    key: 'expired_jwt',
    titleKey: 'system_messages.email_link_expired.title',
    descriptionKey: 'system_messages.email_link_expired.description',
    variation: 'error',
    gaEvent: 'UserActivationFail',
  },
  // activation link token not found (expired and cleaned up)
  token_not_found: {
    key: 'token_not_found',
    titleKey: 'system_messages.email_link_expired.title',
    descriptionKey: 'system_messages.email_link_expired.description',
    variation: 'error',
    gaEvent: 'UserActivationFail',
  },
  // a failure occurred when attempting to patch the active field of the user in the LIP
  patch_failed: getGenericError('patch_failed'),
  // a failure occurred when attempting to patch the active field of the user in the LIP
  invalid_jwt: getGenericError('invalid_jwt'),
  // any other type of exception
  generic: getGenericError('generic'),
}

export const USER_FEEDBACK: { [key: string]: string } = {
  saveSearch: 'saveSearch',
}

export const SYSTEM_MESSAGES: SystemMessageDictionary = {
  // user activated
  ua: {
    key: 'ua',
    titleKey: 'system_messages.user_activated.title',
    descriptionKey: 'system_messages.user_activated.description',
    variation: 'success',
    gaEvent: 'UserActivationSuccess',
  },
  // user activation required
  uar: {
    key: 'uar',
    titleKey: 'system_messages.user_activation_required.title',
    descriptionKey: 'system_messages.user_activation_required.description',
    variation: 'success',
  },
  // user signed in
  uli: {
    key: 'uli',
    titleKey: 'system_messages.user_signed_in.title',
    variation: 'success',
    gaEvent: 'LoginSuccess',
  },
  // user signed out
  ula: {
    key: 'ula',
    titleKey: 'system_messages.user_signed_out.title',
    variation: 'success',
    gaEvent: 'LogoutSuccess',
  },
  // password reset email sent
  pres: {
    key: 'pres',
    titleKey: 'system_messages.user_password_reset_email.title',
    descriptionKey: 'system_messages.user_password_reset_email.description',
    variation: 'success',
  },
  // password reset successful
  prs: {
    key: 'prs',
    titleKey: 'system_messages.user_password_reset.title',
    variation: 'success',
  },
  // save search successful
  sss: {
    key: 'sss',
    titleKey: 'system_messages.save_search_success.title',
    descriptionKey: 'system_messages.save_search_success.description',
    variation: 'success',
    links: ['system_messages.save_search_success.my_favourites_page_link'],
  },
  // save search failed
  ssf: {
    key: 'ssf',
    titleKey: 'system_messages.generic_error.title',
    descriptionKey: 'system_messages.save_search_failed.description',
    variation: 'error',
  },
  // save search guest successful
  sssg: {
    key: 'sssg',
    titleKey: 'system_messages.save_search_guest_success.title',
    variation: 'success',
  },
  // save search guest failed
  ssfg: {
    key: 'ssfg',
    titleKey: 'system_messages.save_search_guest_failed.title',
    descriptionKey: 'system_messages.save_search_guest_failed.description',
    variation: 'error',
  },
}
