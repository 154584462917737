import { type CookieRegistry } from '@/constants/cookieRegistry'

const COOKIE_DELIMITER = '; '

export const getCookieByKey = (cookies: string | undefined, key: CookieRegistry) => {
  if (!cookies) {
    return ''
  }

  const selectedCookie = cookies.split(COOKIE_DELIMITER).find((cookie) => cookie.startsWith(key))

  if (selectedCookie) {
    const idx = selectedCookie.indexOf('=')
    return selectedCookie.slice(idx + 1)
  }

  return ''
}
