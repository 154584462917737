import {
  ProvinceAbbreviation,
  useGetGeocodeReverseFromIpQuery,
} from '@kijiji/generated/graphql-types'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { useTheme } from 'styled-components'

import { CookieConsentModal } from '@/components/shared/cookie-permissions/CookieConsentModal'
import {
  CookieConsentButtons,
  CookieConsentContent,
} from '@/components/shared/cookie-permissions/styled'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { HTTP_STATUS } from '@/constants/httpStatus'
import useCookiePreferences from '@/hooks/useCookiePreferences'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { getGqlErrorStatusCode } from '@/utils/errors'
import { isServer } from '@/utils/isSSR'
import { sendToLogger } from '@/utils/sendToLogger'

/* We conditionally load the cookies banner depending on whether the user has a Quebec
IP address. Consequently, lazy loading our components such that they are imported
only when rendered reduces the banner's effect on our CWV (core web vitals) */
const BodyText = dynamic(() => import('@/ui/atoms/body-text').then((module) => module.BodyText), {
  ssr: false,
})
const Button = dynamic(() => import('@/ui/atoms/button').then((module) => module.Button), {
  ssr: false,
})
const Drawer = dynamic(() => import('@/ui/atoms/drawer').then((module) => module.Drawer), {
  ssr: false,
})
const HeadlineText = dynamic(
  () => import('@/ui/atoms/headline-text').then((module) => module.HeadlineText),
  {
    ssr: false,
  }
)
const Spacing = dynamic(() => import('@/ui/atoms/spacing').then((module) => module.Spacing), {
  ssr: false,
})

export type AcceptAllSource = 'acceptButton' | 'consentModal'

export const CookiePermissions = () => {
  const { getCookiePreferences, setCookiePreferences } = useCookiePreferences()
  const [shouldShowBanner, setShouldShowBanner] = useState(false)
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false)
  const { pathname } = useRouter()
  const { t } = useTranslation('common')
  const { spacing } = useTheme()

  /**
   * We shouldn't show the cookie consent banner if the user has already set their cookie preferences.
   */
  const hasUserSetPreferences = isServer() ? undefined : !!getCookiePreferences()

  /* NOTE: The cookie banner will not appear if the geocode reverse from IP endpoint returns an
  error. There may be legal implications if the user is, in fact, located in Quebec. */
  useGetGeocodeReverseFromIpQuery({
    skip: hasUserSetPreferences,
    ssr: false,
    onCompleted: (data) => {
      setShouldShowBanner(data.geocodeReverseFromIp.province === ProvinceAbbreviation.Qc)
    },
    onError: (error) => {
      if (getGqlErrorStatusCode(error) !== HTTP_STATUS.NOT_FOUND) {
        sendToLogger(error, {
          tags: { fn: 'getUserLocation', component: 'CookiePermissions' },
          fingerprint: ['CookiePermissions'],
        })
      }
    },
  })

  const onCloseBanner = () => {
    trackEvent({ action: GA_EVENT.CookieAcknowledge, label: `loc=${pathname}` })
    setShouldShowBanner(false)
  }

  const handleAcceptAll = (source: AcceptAllSource) => {
    setCookiePreferences({
      optOutTargeting: false,
      optOutAnalyticsAndPerformance: false,
    })

    if (source === 'acceptButton') {
      trackEvent({ action: GA_EVENT.CookieAcknowledge, label: 'cta=acknowledge_all' })
      setShouldShowBanner(false)
    } else if (source === 'consentModal') {
      trackEvent({
        action: GA_EVENT.CookieAcceptAll,
        label: `cta=accept_all;loc=${pathname};FunctionalCookie=1;AnalyticsCookie=1;TargetingCookie=1`,
      })
      setIsConsentModalOpen(false)
    }
  }

  const handleManagePreferences = () => {
    trackEvent({
      action: GA_EVENT.CookieManagePreferences,
      label: `cta=manage_cookies;loc=${pathname}`,
    })
    setShouldShowBanner(false)
    setIsConsentModalOpen(true)
  }

  if (!shouldShowBanner && !isConsentModalOpen) {
    return null
  }

  return (
    <>
      <Drawer
        label="Cookies consent banner"
        closeButtonLabel={t('cookies.banner.close')}
        isOpen={shouldShowBanner}
        onCancel={onCloseBanner}
      >
        <Spacing mBottom={spacing.large}>
          <HeadlineText size="large" as="h2">
            {t('cookies.consent.title')}
          </HeadlineText>
        </Spacing>
        <CookieConsentContent data-testid="cookie-consent-banner">
          <BodyText size="small">
            {t('cookies.consent.description')}
            <LinkCustom
              hasUnderline
              key="cookiePolicyLink"
              rel="noreferrer"
              size="small"
              target="_blank"
              variant="secondary"
              href={`${t('cookies.banner.cookie_policy.kijiji.href')}`}
            >
              {t('cookies.consent.policy')}
            </LinkCustom>
            .
          </BodyText>
          <CookieConsentButtons>
            <Button variant="secondary" size="small" onClick={handleManagePreferences}>
              {t('cookies.consent.manage')}
            </Button>
            <Button size="small" onClick={() => handleAcceptAll('acceptButton')}>
              {t('cookies.consent.accept')}
            </Button>
          </CookieConsentButtons>
        </CookieConsentContent>
      </Drawer>
      <CookieConsentModal
        isOpen={isConsentModalOpen}
        handleAcceptAll={handleAcceptAll}
        handleClose={() => setIsConsentModalOpen(false)}
      />
    </>
  )
}
