/* eslint-disable no-console*/
/* eslint-disable @typescript-eslint/no-explicit-any */
import { onError } from '@apollo/client/link/error'

import handleGraphQLError from '@/lib/apollo/utils/handleGraphQLError'

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(handleGraphQLError)
  }

  if (networkError) {
    console.warn(`[Network error on operation ${operation.operationName}]: ${networkError}`)
  }
})

export default errorLink
