import { useCallback, useRef, useState } from 'react'

export const stateDataAttribute = 'data-state'
export const openState = 'open'

/**
 * Custom hook to track the expanded state of an AccordionItem based on the `data-state` attribute.
 *
 * @returns {Array} An array containing:
 *  - {boolean} isExpanded - The current expanded state.
 *  - {React.RefObject<HTMLDivElement>} ref - The ref to be attached to the target element.
 */
export const useIsExpandedItem = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const observerRef = useRef<MutationObserver | null>(null)

  const handleRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      const initialState = node.getAttribute(stateDataAttribute) === openState
      setIsExpanded(initialState)

      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === stateDataAttribute
          ) {
            const newState = node.getAttribute(stateDataAttribute) === openState
            setIsExpanded(newState)
          }
        }
      })

      observer.observe(node, {
        attributes: true,
        attributeFilter: [stateDataAttribute],
      })

      observerRef.current = observer
    } else {
      observerRef.current?.disconnect()
    }
  }, [])

  return { isExpanded, handleRef }
}
