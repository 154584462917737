import { ThemeProps } from '@kijiji/theme'

export const MEDIA_QUERIES = (theme: ThemeProps) => ({
  small: `@media screen and (min-width: ${theme.breakpoints.small}px)`,
  mediumSmall: `@media screen and (min-width: ${theme.breakpoints.mediumSmall}px)`,
  medium: `@media screen and (min-width: ${theme.breakpoints.medium}px)`,
  large: `@media screen and (min-width: ${theme.breakpoints.large}px)`,
  xLarge: `@media screen and (min-width: ${theme.breakpoints.xLarge}px)`,
})

/**
 * embla-carousel requires media queries to be in the format:
 * (min-width: 1024px) instead of @media screen and (min-width: 1024px)
 */
export const MEDIA_QUERIES_SHORT = (theme: ThemeProps) => ({
  small: `(min-width: ${theme.breakpoints.small}px)`,
  mediumSmall: `(min-width: ${theme.breakpoints.mediumSmall}px)`,
  medium: `(min-width: ${theme.breakpoints.medium}px)`,
  large: `(min-width: ${theme.breakpoints.large}px)`,
  xLarge: `(min-width: ${theme.breakpoints.xLarge}px)`,
})
