import { DAY_IN_MS } from '@/utils/time'

// this is the max allowed value for `maxAge` cookie expiry (68 years from now)
export const MAX_INTEGER_SAFE_MAX_AGE = 2147483646

/**
 * Calculates the maximum safe expiration time for cookies in days.
 *
 * This function calculates the number of days between the current time (`Date.now()`)
 * and January 1, 2038. This date is used due to the "Year 2038 problem," which limits
 * the maximum expiration date for cookies on certain systems. A small buffer is applied
 * to ensure that the calculated expiration time is within safe limits.
 *
 * @returns {number} The maximum number of days until the safe expiration date.
 *
 * @see https://en.wikipedia.org/wiki/Year_2038_problem
 */
export const getMaxSafeExpiryInDays = () => {
  const now = Date.now()
  // roughly the max safe date with a small buffer
  const maxSafeDate = new Date('2038-01-01T00:00:00Z').getTime()

  // Calculate the difference in milliseconds, then convert to days
  const maxSafeExpiryInDays = Math.floor((maxSafeDate - now) / DAY_IN_MS)

  return maxSafeExpiryInDays
}
