import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgClose: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.853 11.996l4.975-4.976a.6.6 0 00-.848-.849l-4.976 4.976-4.976-4.976a.6.6 0 00-.848.849l4.976 4.976-4.976 4.976a.6.6 0 10.848.848l4.976-4.976 4.976 4.976a.6.6 0 00.848-.848l-4.975-4.976z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgClose
