export type FlexCoreAttributes = {
  /**
   * Defines the default behavior for how flex items are laid out along the cross axis on the current line
   */
  alignItems?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'stretch'
  /**
   * Defines the default behavior for how flex items are laid out along the cross axis on the current line
   */
  alignSelf?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'stretch'
  /**
   * Establishes the main-axis for items placed inside of this container
   */
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  /**
   * Establishes if items will all fit in one line or wrap
   */
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  /**
   * Defines the alignment along the main axis
   */
  justifyContent?:
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'

  /**
   * Sets the gap between the rows and columns in a flexbox container. Is a shorthand for row-gap and column-gap.
   */
  gap?: string
  /**
   * Sets the gap size between the columns in a flexbox container.
   */
  columnGap?: string
  /**
   * Sets the gap size between the rows in a flexbox container.
   */
  rowGap?: string
  /**
   * Sets the flex grow factor of a flex item main size. It specifies how much of the remaining space in the flex container should be assigned to the item (the flex grow factor).
   */
  flexGrow?: number
  /**
   * Sets the flex shrink factor of a flex item. It specifies how much the item will shrink relative to the rest of the flexible items inside the same container.
   */
  flexShrink?: number
  /**
   * Sets the initial length of a flex item. It specifies the initial main size of the item, before any free space is distributed according to the flex factors.
   */
  flexBasis?: string
}

export type FlexVariations = keyof FlexCoreAttributes

export const flexVariationToProperty: {
  [x in FlexVariations]: string
} = {
  alignItems: 'align-items',
  flexDirection: 'flex-direction',
  flexWrap: 'flex-wrap',
  justifyContent: 'justify-content',
  gap: 'gap',
  columnGap: 'column-gap',
  rowGap: 'row-gap',
  flexGrow: 'flex-grow',
  flexShrink: 'flex-shrink',
  flexBasis: 'flex-basis',
  alignSelf: 'align-self',
}

const flexVariationToValue = (
  variation: FlexVariations,
  value?: FlexCoreAttributes[keyof FlexCoreAttributes]
) => {
  return value ? `${flexVariationToProperty[variation]}: ${value};` : ''
}

export const flex = (props?: FlexCoreAttributes) => {
  if (!props) return ''

  return Object.keys(props).reduce((acc: string, curr: string) => {
    const key = curr as FlexVariations

    if (!props[key]) return acc

    return `${acc} ${flexVariationToValue(key as FlexVariations, props[key])}`
  }, '')
}
