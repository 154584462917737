import isMobile from 'ismobilejs'

/**
 * Determines if the user is on a mobile device using ismobilejs library
 * https://github.com/kaimallea/isMobile?tab=readme-ov-file#ismobile
 * @param userAgent optional userAgent string only needed if called from server side
 * @returns object with device type booleans and isMobile function
 */
export const getUserDevice = (userAgent?: string) => {
  const returnValues = isMobile(userAgent)

  return {
    isPhoneOrTablet: returnValues.any,
    isPhone: returnValues.phone,
    isTablet: returnValues.tablet,
    isDesktop: !returnValues.any,
    isAppleDevice: returnValues.apple.device,
    isAndroidDevice: returnValues.android.device,
    isMobile, // pass through isMobile function to cover more specific cases
  }
}
