import { type UserLocation } from '@kijiji/generated/graphql-types'

import { LatestSearchCookieRegistry } from '@/constants/cookieRegistry'
import {
  ALL_CANADA_LOCATION_ID,
  DEFAULT_USER_LOCATION,
  RADIUS_SLIDER_HALF_POINT_KMS,
} from '@/constants/location'
import locationTree from '@/constants/location/locationTree.json'
import {
  type LatestSearchCookie,
  decodeLocationCookieAddress,
} from '@/utils/cookies/latestSearchCookie'

export const convertCookieToUserLocation = (latestSearch: LatestSearchCookie): UserLocation => {
  const locationId = latestSearch[LatestSearchCookieRegistry.LOCATION_ID]

  if (!locationId || parseInt(locationId) === ALL_CANADA_LOCATION_ID) return DEFAULT_USER_LOCATION

  const latitude = latestSearch[LatestSearchCookieRegistry.LATITUDE] || ''
  const longitude = latestSearch[LatestSearchCookieRegistry.LONGITUDE] || ''
  const radius = latestSearch[LatestSearchCookieRegistry.RADIUS]
  const address = latestSearch[LatestSearchCookieRegistry.ADDRESS] || ''

  const streetAddress = address ? decodeLocationCookieAddress(address) : ''
  /**
   * Defines if the location is a region
   * Which means it has a lat/long defined
   *
   * The lat/long are always defined on a region location, but not the street address
   * This is our best bet right now to correctly assume if this location is a region
   * The definition is currently defined on the local storage and will update our location if we have guessed incorrectly
   *
   * TODO: Ideally the information saved on the local storage should be available in the cookies.
   */
  const isRegion = !streetAddress || !latitude || !longitude

  /** Get the location name from our static location tree */
  const locationData = locationTree.find((item) => item.id === parseInt(locationId))
  const locationName = locationData?.name || { en_CA: '', fr_CA: '' }

  const location: UserLocation = {
    isRegion,
    id: parseInt(locationId),
    name: locationName,
    area: null,
  }

  if (isRegion) return location

  location.area = {
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
    radius: radius ? parseInt(radius) : RADIUS_SLIDER_HALF_POINT_KMS,
    address: streetAddress,
  }

  return location
}
