import { CookieRegistry } from '@/constants/cookieRegistry'
import { createCookie } from '@/utils/cookies/createCookie'

/**
 * Sets the Cookie to define what was the user last set radius value - Value should be available server-side
 * This will help return to this value whenever we automatically update the radius base on the browsed category
 */
export const setRadiusBoostCookie = (value: boolean) => {
  /** Not passing an expiryInDays in the cookie creation will make this a session cookie */
  createCookie(CookieRegistry.MANUALLY_UPDATED_RADIUS, value.toString())
}
