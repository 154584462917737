import styled from 'styled-components'

import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

export const Label = styled.label(
  ({ theme }) => `
    ${styleFromTypography(theme.typography.body.medium)}
    align-items: center;
    color: ${theme.colors.grey.primary};
    font-family: inherit;
`
)
