import CloseIcon from '@kijiji/icons/src/icons/Close'
import { HTMLAttributes } from 'react'

import { StyledModalCloseButton } from '@/ui/molecules/modal/styled'

type ModalCloseButtonProps = HTMLAttributes<HTMLButtonElement> & {
  isDarkModal?: boolean
}

export const ModalCloseButton = ({
  isDarkModal,
  ...props
}: ModalCloseButtonProps) => {
  return (
    <StyledModalCloseButton type="button" isDarkModal={isDarkModal} {...props}>
      <CloseIcon aria-hidden />
    </StyledModalCloseButton>
  )
}
