const logger = () => {
  const isClient = typeof window !== 'undefined'

  if (process.env.NODE_ENV === 'development' && isClient) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { default: apolloLogger } = require('apollo-link-logger')
    return [apolloLogger]
  }

  return []
}

export default logger
