type ValidateRedirectDomainFn = (redirect: URL, redirectUrlDomainWhitelist: string) => boolean;
type GetBaseUrlFn = () => string;

/**
 * Creates a function that generates absolute URLs from relative paths.
 * (TODO: move this to @kijiji/utils)
 *
 * @param {ValidateRedirectDomainFn} validateRedirectDomain - A function to validate if a given URL is in the allowed domain whitelist.
 * @param {GetBaseUrlFn} getBaseUrl - A function that returns the base URL to be used when no valid baseUrl is provided.
 * @param {string} redirectDomainWhitelist - A string containing the allowed domains for redirection.
 * @returns {(relativePath: string, baseUrl?: URL) => string} A function that takes a relative path and an optional base URL, and returns an absolute URL.
 *
 * @example
 * const getAbsoluteUrl = createGetAbsoluteUrl(
 *   validateRedirectDomain,
 *   () => 'https://www.example.com',
 *   'example.com,example.org'
 * );
 * const absoluteUrl = getAbsoluteUrl('/path/to/resource', new URL('https://subdomain.example.com'));
 */
export function createGetAbsoluteUrl(
  validateRedirectDomain: ValidateRedirectDomainFn,
  getBaseUrl: GetBaseUrlFn,
  redirectDomainWhitelist: string
) {
  return (relativePath: string, baseUrl?: URL): string => {
    let base = baseUrl && validateRedirectDomain(baseUrl, redirectDomainWhitelist)
      ? baseUrl.origin
      : getBaseUrl()

    // Remove trailing slash from base if it exists
    base = base.replace(/\/$/, '')

    // Handle empty relative path
    if (!relativePath) {
      return base
    }

    // Handle relative paths not starting with /
    if (!relativePath.startsWith('/')) {
      relativePath = `/${relativePath}`
    }

    return `${base}${relativePath}`
  }
}
