/* eslint-disable no-console*/
/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ScopeContext } from '@sentry/types'
import { type GraphQLError } from 'graphql'

import { logger } from '@/utils/sendToLogger'

const ignoredErrorQueries = ['geocodeReverseFromIp']
const ignoredStatusCodes = [404]

const shouldReportError = (
  path: Array<string | number> | undefined,
  extensions: { [key: string]: any }
) => {
  if (
    ignoredErrorQueries.includes(path?.[0] as string) ||
    ignoredStatusCodes.includes(extensions.statusCode)
  ) {
    return false
  }
  return true
}

const handleGraphQLError = ({ message, locations, path, extensions }: GraphQLError) => {
  const error = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
    locations
  )}, Path: ${path}`
  // Destructure readonly array into a mutable array
  if (shouldReportError(Array.isArray(path) ? [...path] : undefined, extensions)) {
    const context: Partial<ScopeContext> = {
      level: 'error',
      fingerprint: ['graphQLError'],
    }
    if (path && Array.isArray(path)) {
      context.fingerprint?.push(...path.map((pathSegment) => `${pathSegment}`))
    }
    if (extensions.statusCode) {
      context.fingerprint?.push(extensions.statusCode)
    }
    if (typeof window !== 'undefined') {
      context.extra = {
        pathname: window.location.pathname,
      }
    }
    logger.captureMessage(error, context)
    console.error(error)
  }
}

export default handleGraphQLError
