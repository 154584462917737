/**
 * @description Converts typography definition into proper font style.
 *
 * @param typography - Object from the theme
 *
 * @returns css
 */
export const styleFromTypography = (typography: {
  fontSize: string
  lineHeight: string
}) => `
  font-size: ${typography?.fontSize};
  line-height: ${typography?.lineHeight};
`
