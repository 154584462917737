type AvailableColors =
  | 'blue'
  | 'green'
  | 'grey'
  | 'purple'
  | 'red'
  | 'violet'
  | 'yellow'

export type Colors = ColorPallet & {
  white: string
}

export type ColorPallet = {
  [color in AvailableColors]: {
    light1: string
    light2: string
    light3: string
    light4: string
    light5: string
    primary: string
    dark1: string
    dark2: string
    dark3: string
  }
}

export const colors: Colors = {
  white: '#FFFFFF',
  purple: {
    light1: '#4B4881',
    light2: '#696696',
    light3: '#918FBA',
    light4: '#CACAEA',
    light5: '#F3F3F9',
    primary: '#373373',
    dark1: '#23234F',
    dark2: '#171935',
    dark3: '#0B0C1F',
  },
  blue: {
    light1: '#3B97E2',
    light2: '#69B8ED',
    light3: '#9CD4F2',
    light4: '#CBEAF4',
    light5: '#F0FAFC',
    primary: '#1477D4',
    dark1: '#155E9B',
    dark2: '#103A56',
    dark3: '#08283A',
  },
  green: {
    light1: '#56C17C',
    light2: '#90D3A6',
    light3: '#BAE5C6',
    light4: '#DAEFDF',
    light5: '#F4FAF5',
    primary: '#37A864',
    dark1: '#2D844C',
    dark2: '#215934',
    dark3: '#11351C',
  },
  yellow: {
    light1: '#FFB92E',
    light2: '#FFD255',
    light3: '#F9DB91',
    light4: '#FEEED1',
    light5: '#FFFBF2',
    primary: '#F8AA17',
    dark1: '#D49129',
    dark2: '#AA6F29',
    dark3: '#845726',
  },
  red: {
    light1: '#F96262',
    light2: '#FF8383',
    light3: '#FABEC2',
    light4: '#FCDADC',
    light5: '#FEF4F5',
    primary: '#F1454F',
    dark1: '#BB3C4D',
    dark2: '#993244',
    dark3: '#721F31',
  },
  violet: {
    light1: '#AC60BF',
    light2: '#C48FD1',
    light3: '#DCBEE2',
    light4: '#EBDAEF',
    light5: '#FAF3F8',
    primary: '#9B44AD',
    dark1: '#793889',
    dark2: '#5D2C68',
    dark3: '#401D49',
  },
  grey: {
    light1: '#6F727F',
    light2: '#8E909B',
    light3: '#D2D3D7',
    light4: '#ECECEE',
    light5: '#F8F9F9',
    primary: '#3E4153',
    dark1: '#343746',
    dark2: '',
    dark3: '',
  },
}
