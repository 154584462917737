import { CookieRegistry } from '@/constants/cookieRegistry'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'
import { isServer } from '@/utils/isSSR'

/**
 * Returns the hashed user id (it's also known as huid) from the cookie
 *
 * @function
 * @returns {string} An string representing the hashed user id
 */
export const getHashedUserIdFromCookie = () => {
  // return undefined to mimic behaviour on legacy
  if (isServer()) return undefined

  return getCookieByKey(document.cookie, CookieRegistry.HASHED_USER_ID) || ''
}
