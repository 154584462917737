/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeProps } from '@kijiji/theme'

import { MEDIA_QUERIES } from '../constants/mediaQueries'
import { ResponsiveProp } from '../typings/helpers'

const isObjectOfBreakpoints = <T>(
  object: {
    [x in keyof ThemeProps['breakpoints']]?: T
  },
  theme: ThemeProps
): object is {
  [x in keyof ThemeProps['breakpoints']]?: T
} => {
  const breakpointsArray = Object.keys(theme.breakpoints)
  return !!Object.keys(object).find((key) => breakpointsArray.includes(key))
}

/**
 * Helper to automatically apply responsive design for all breakpoints defined
 *
 * @param responsiveProp
 * @param theme
 * @param cssVariation
 * @returns
 */
export const applyResponsiveStyle = <T>(
  responsiveProp: ResponsiveProp<T>,
  theme: ThemeProps,
  cssVariation: (value: T, theme: ThemeProps) => string
): string => {
  // Return early if no value is provided
  if (!responsiveProp) return ''

  // If `responsiveProp` is not an object, assume it's a single value and return the CSS without media queries
  if (typeof responsiveProp !== 'object') {
    return cssVariation(responsiveProp, theme)
  }

  if (!isObjectOfBreakpoints(responsiveProp, theme)) return ''

  return Object.keys(responsiveProp).reduce((acc: string, curr: string) => {
    const key = curr as keyof ThemeProps['breakpoints']
    const value = responsiveProp[key]

    // If no value is provided for this breakpoint, skip
    if (typeof value === 'undefined') return acc

    // For the smallest breakpoint (e.g., "small"), apply CSS directly without a media query
    if (key === 'small') {
      return `
        ${cssVariation(value, theme)}
        ${acc}
      `
    }

    // For larger breakpoints, wrap the CSS in media queries
    return `
        ${acc}
        ${MEDIA_QUERIES(theme)[key]}{
          ${cssVariation(value, theme)}
        }
      `
  }, '')
}
