import { ReactNode } from 'react'

import { StyledAccordion } from './styled'

interface CommonAccordionProps {
  children: ReactNode
  /**
   * The gap between items in the accordion in rems
   * @default 2
   */
  gap?: number
}

interface AccordionPropsMultiple extends CommonAccordionProps {
  /**
   * Allow multiple accordion fields to be expanded at the same time
   */
  allowMultipleExpanded?: true
  /**
   * The value of the item to expand when initially rendered. Use when you do not need to control the state of the items.
   */
  preExpanded?: string[]
  /**
   * The controlled value of the accordion. Must be used in conjunction with onChange.
   */
  value?: string[]
  /**
   * Callback which is invoked when items are expanded or collapsed.
   * Gets passed uuids of the currently expanded AccordionItems
   */
  onChange?: (uuid: string[]) => void
}
interface AccordionPropsSingle extends CommonAccordionProps {
  /**
   * Allow multiple accordion fields to be expanded at the same time
   */
  allowMultipleExpanded?: false
  /**
   * The value of the item to expand when initially rendered. Use when you do not need to control the state of the items.
   */
  preExpanded?: string
  /**
   * The controlled value of the accordion. Must be used in conjunction with onChange.
   */
  value?: string
  /**
   * Callback which is invoked when items are expanded or collapsed.
   * Gets passed uuids of the currently expanded AccordionItems
   */
  onChange?: (uuid: string) => void
}

export type AccordionProps = AccordionPropsMultiple | AccordionPropsSingle

export const Accordion = ({
  gap = 2,
  children,
  preExpanded,
  onChange,
  value,
  allowMultipleExpanded = true,
  ...rest
}: AccordionProps) => {
  const accordionProps = allowMultipleExpanded
    ? {
        defaultValue: preExpanded as string[] | undefined,
        onValueChange: onChange as ((uuid: string[]) => void) | undefined,
        value: value as string[] | undefined,
        type: 'multiple' as const,
      }
    : {
        defaultValue: preExpanded as string | undefined,
        onValueChange: onChange as ((uuid: string) => void) | undefined,
        value: value as string | undefined,
        type: 'single' as const,
      }

  return (
    <StyledAccordion
      data-testid="accordion-wrapper"
      gap={gap}
      {...accordionProps}
      {...rest}
    >
      {children}
    </StyledAccordion>
  )
}
