import styled from 'styled-components'

import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { RequiredPickMap } from '@/ui/typings/helpers'

import { BodyTextProps } from './BodyText'

type BodyTextBaseProps = RequiredPickMap<
  BodyTextProps,
  'color' | 'size' | 'textAlign' | 'weight'
>

export const BodyTextBase = styled.p<BodyTextBaseProps>(
  ({ $weight, $color, $size, $textAlign, theme }) => `
  ${styleFromTypography(theme.typography.body[$size])}
  color: ${$color};
  font-family: inherit;
  font-weight: ${theme.fontWeight[$weight]};
  text-align: ${$textAlign};
`
)
