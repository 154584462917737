import { type NextRouter } from 'next/router'

import { CIS_ERROR_MESSAGES, SYSTEM_MESSAGES } from '@/constants/systemMessages'

/**
 * Returns a boolean value indicating if the current query object has a system message parameter
 *
 * We currently have a logic on the global system message where it will use the query parameter to show the system message
 * and then re-render the URL without them. (The logic should be replaced later with a shallow routing to avoid re-renders)
 *
 * As the current implementation can cause tracking issues, this domain helps us find out if there is any query parameters on the URL
 * This will mean the page will still re-render and we might skip tracking before those values are cleared from the URL
 */
export const hasSystemMsgQueryParam = (query: NextRouter['query']): boolean => {
  const hasParameter = Object.keys(query).find((item) => {
    const value = query[item]?.toString()
    return (
      (item === 'error' && value && CIS_ERROR_MESSAGES[value]) ||
      (value === 'true' && SYSTEM_MESSAGES[item])
    )
  })

  return !!hasParameter
}
