const { generateEnvSuffix } = require('./generate-asset-prefix.js')

/**
 * Generates a full URL path to the locale files based on the environment and specified locale file name.
 * @param {string} environment - The current environment of the application (e.g., 'local', 'production', 'staging').
 * @example
 * generateLocalePath('int');
 * returns https://webapp-static.ca-kijiji-int.classifiedscloud.io/locales
 */

function generateLocalePath(environment, lng, ns) {
  const envSuffix = generateEnvSuffix(environment)
  return `https://webapp-static.ca-kijiji-${envSuffix}.classifiedscloud.io/${process.env.NEXT_PUBLIC_WEBAPP_VERSION}/locales/${lng}-CA/${ns}.json`
}

function generateLocalePathFromAssetPrefix(assetPrefix, lng, ns) {
  return `${assetPrefix}/locales/${lng}-CA/${ns}.json`
}

module.exports = {
  generateLocalePath,
  generateLocalePathFromAssetPrefix,
}
