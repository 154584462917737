import CommunityGroupIcon from '@kijiji/icons/src/icons/CommunityGroup'
import CustomizeIcon from '@kijiji/icons/src/icons/Customize'
import DataChartsIcon from '@kijiji/icons/src/icons/DataCharts'
import PackageCostIcon from '@kijiji/icons/src/icons/PackageCost'
import PriceTagIcon from '@kijiji/icons/src/icons/PriceTag'
import ReportIcon from '@kijiji/icons/src/icons/Report'
import VisibilityIcon from '@kijiji/icons/src/icons/Visibility'
import { type TFunction } from 'next-i18next'

export type AccountType = {
  type: ACCOUNT_TYPE
  headerText: string
  isFree: boolean
  perks: {
    Icon: React.ReactNode
    description: string
  }[]
  createButtonText: string
}

export enum ACCOUNT_TYPE {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}

export const getAccountTypes = (t: TFunction): AccountType[] => [
  {
    type: ACCOUNT_TYPE.PERSONAL,
    headerText: t('landing.h2_personal_account'),
    isFree: true,
    perks: [
      { Icon: <PriceTagIcon />, description: t('landing.account_perks.post') },
      { Icon: <ReportIcon />, description: t('landing.account_perks.manage_ads') },
      { Icon: <CommunityGroupIcon />, description: t('landing.account_perks.contact') },
      { Icon: <DataChartsIcon />, description: t('landing.account_perks.personalized') },
    ],
    createButtonText: t('landing.create_personal'),
  },
  {
    type: ACCOUNT_TYPE.BUSINESS,
    headerText: t('landing.h2_business_account'),
    isFree: true,
    perks: [
      { Icon: <VisibilityIcon />, description: t('landing.account_perks.visibility') },
      { Icon: <ReportIcon />, description: t('landing.account_perks.manage_inventory') },
      { Icon: <PackageCostIcon />, description: t('landing.account_perks.promote') },
      { Icon: <CustomizeIcon />, description: t('landing.account_perks.customize') },
    ],
    createButtonText: t('landing.create_business'),
  },
]
