import { useEffect, useRef } from 'react'

/**
 * Preserves the value of the passed variable across re-renders
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
