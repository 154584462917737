import { type RecentSearchLocation } from '@/components/shared/search-location-modal/useLocationSearchSuggestions'
import { LocalStorageRegistry } from '@/constants/localStorageRegistry'
import { sendToLogger } from '@/utils/sendToLogger'

export const getRecentLocationsFromLocalStorage = (): RecentSearchLocation[] => {
  if (typeof window === 'undefined') return []

  const storedSearchLocations = window.localStorage.getItem(LocalStorageRegistry.RECENT_LOCATIONS)

  if (!storedSearchLocations) return []

  try {
    const recentSearchLocations = JSON.parse(storedSearchLocations)
    return recentSearchLocations
  } catch (e) {
    sendToLogger(e, {
      tags: { fn: 'getRecentLocationsFromLocalStorage' },
      fingerprint: ['JsonParse'],
      extra: { value: `stored-recent-locations: ${storedSearchLocations}` },
    })
  }

  return []
}
