import { ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { applyResponsiveStyle } from '@/ui/helpers/applyResponsiveStyle'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { RequiredPickMap } from '@/ui/typings/helpers'

import { HeadlineSize, HeadlineTextProps, HeadlineWeight } from './HeadlineText'

type HeadlineTextBaseProps = RequiredPickMap<
  HeadlineTextProps,
  'weight' | 'color' | 'size' | 'textAlign'
>

const getResponsiveSize = (value: HeadlineSize, theme: ThemeProps) =>
  styleFromTypography(theme.typography.headline[value])

const getResponsiveWeight = (value: HeadlineWeight, theme: ThemeProps) =>
  `font-weight: ${theme.fontWeight[value]};`

export const HeadlineTextBase = styled.h1<HeadlineTextBaseProps>(
  ({ $weight, $color, $size, $textAlign, theme }) => `
    ${applyResponsiveStyle($size, theme, getResponsiveSize)}
    ${applyResponsiveStyle($weight, theme, getResponsiveWeight)}

    color: ${$color};
    font-family: inherit;
    text-align: ${$textAlign};
  `
)
