import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client'
import { type UserLocation, GetUserLocationDocument } from '@kijiji/generated/graphql-types'

import { initializeApollo } from '@/lib/apollo/apolloClient'

export const updateUserLocationCache = (
  location: UserLocation,
  client?: ApolloClient<NormalizedCacheObject>
) => {
  if (!client) {
    client = initializeApollo()
  }

  client.cache.writeQuery({
    query: GetUserLocationDocument,
    data: { userLocation: location },
  })
}
