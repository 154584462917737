import { PlusMinusContainer } from './styled'

export type PlusMinusProps = {
  isActive: boolean
}

/**
 * A simple plus sign component that transitions to a minus sign when the isActive prop is true
 *
 * @param isActive - When isActive is false the user sees a plus sign, when isActive is true the user sees a minus sign
 */
export const PlusMinus = ({ isActive, ...rest }: PlusMinusProps) => {
  return <PlusMinusContainer isActive={isActive} {...rest} />
}
