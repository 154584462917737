import { ACCOUNT_TYPE } from '@/features/auth/components/register/utils'

export const UserActivationFail = 'UserActivationFail'
export const UserActivationSuccess = 'UserActivationSuccess'
export const UserRegistrationAttempt = 'UserRegistrationAttempt'
export const UserRegistrationBegin = 'UserRegistrationBegin'
export const UserRegistrationFail = 'UserRegistrationFail'
export const UserRegistrationSuccess = 'UserRegistrationSuccess'

/**
 * getUserRegistrationAttemptLabel | UserRegistrationAttemptLabelInput
 */
type UserRegistrationAttemptLabelInput = {
  accountType: ACCOUNT_TYPE
}
export const getUserRegistrationAttemptLabel = ({
  accountType,
}: UserRegistrationAttemptLabelInput) => {
  return accountType === ACCOUNT_TYPE.BUSINESS ? ACCOUNT_TYPE.BUSINESS : undefined
}

/**
 * getUserRegistrationFailLabel | UserRegistrationFailLabelInput
 */
type UserRegistrationFailLabelInput = {
  accountType: ACCOUNT_TYPE
}
export const getUserRegistrationFailLabel = ({ accountType }: UserRegistrationFailLabelInput) => {
  return accountType === ACCOUNT_TYPE.BUSINESS ? ACCOUNT_TYPE.BUSINESS : undefined
}

/**
 * getUserRegistrationSuccessLabel | UserRegistrationSuccessLabel
 */
type UserRegistrationSuccessLabelInput = {
  accountType: ACCOUNT_TYPE
}
export const getUserRegistrationSuccessLabel = ({
  accountType,
}: UserRegistrationSuccessLabelInput) => {
  return accountType === ACCOUNT_TYPE.BUSINESS ? ACCOUNT_TYPE.BUSINESS : undefined
}
