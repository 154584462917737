export const isUserAgentAndroid = (userAgent: string) => userAgent.match(/Android/i) !== null
export const isUserAgentIos = (userAgent: string) => userAgent.match(/iPhone|iPad|iPod/i) !== null
export const isBrowserSafari = (userAgent: string) =>
  /^((?!chrome|android).)*safari/i.test(userAgent)
export const isSafariIOS = (userAgent: string) =>
  /(?:iPhone|iPad).*((?!chrome|android).)*safari/i.test(userAgent)

const botUserAgentsPattern = new RegExp(
  ['googlebot', 'bingbot', 'linkedinbot', 'mediapartners-google', 'botify'].join('|'),
  'i'
)

export const isUserAgentCrawler = (userAgent: string) => botUserAgentsPattern.test(userAgent)

export const isMobileDevice = (userAgent: string) =>
  isUserAgentAndroid(userAgent) ||
  isUserAgentIos(userAgent) ||
  userAgent.match(/webOS|BlackBerry|IEMobile|Opera Mini/i) !== null
