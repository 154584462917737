import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgReport: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.55 16.627a.648.648 0 01-.649.648H1.475a.648.648 0 01-.648-.648V4.54h11.421l.517-.516H.827V1.252a.648.648 0 01.648-.648H12.9a.648.648 0 01.648.648v1.992l.516-.516V1.252A1.167 1.167 0 0012.901.088H1.475A1.168 1.168 0 00.31 1.252v15.375a1.168 1.168 0 001.164 1.163H12.9a1.166 1.166 0 001.164-1.163V5.313l-.516.515z"
        fill="#363373"
      />
      <path
        d="M3.2 1.305a.868.868 0 10-.002 1.735.868.868 0 00.002-1.735zm0 1.218a.352.352 0 11-.002-.703.352.352 0 01.002.703zM5.756 1.305a.868.868 0 10.005 1.735.868.868 0 00-.005-1.735zm0 1.218a.352.352 0 11-.002-.703.352.352 0 01.002.703z"
        fill="#373373"
      />
      <g fill="#363373">
        <path d="M2.592 11.913h9.434a.258.258 0 000-.516H2.592a.258.258 0 000 .516zM2.592 14.1h9.434a.258.258 0 000-.517H2.592a.258.258 0 000 .516zM2.592 7.534h4.907a.258.258 0 000-.516H2.592a.258.258 0 100 .516zM2.592 9.723h3.347a.258.258 0 100-.516H2.592a.258.258 0 100 .516z" />
      </g>
      <path
        d="M17.64.454a1.26 1.26 0 00-1.776 0l-1.22 1.216-.578.578-.516.516-1.265 1.261-.517.516-2.88 2.88a.454.454 0 00-.063.082.252.252 0 00-.099.096L7.813 9.19l-.72 1.36a.318.318 0 00.048.377.311.311 0 00.218.088c.056 0 .11-.014.159-.04l1.37-.72 1.633-.93a.27.27 0 00.082-.075.385.385 0 00.06-.05l2.89-2.88.517-.514 2.364-2.36 1.21-1.2a1.25 1.25 0 000-1.773zm-9 9.342l-.781.414.411-.77.693-1.22.889.886zm1.668-.977a.071.071 0 01-.035-.021l-.991-.987a.08.08 0 01-.022-.033l5.743-5.734a.084.084 0 01.035.022l.12.12.876.873a.11.11 0 01.017.03zm6.968-6.96l-.854.851-1.046-1.043.85-.848a.745.745 0 011.208.24.737.737 0 01-.16.803z"
        fill="#373373"
      />
    </Icon>
  )
}
export default SvgReport
