import { useRouter } from 'next/router'

/**
 * While not all pages are ready for production use, we shouldn't use push() to transition to their legacy counterparts.
 * @returns {Function(url: string)} - Function that takes the url and performs the transition.
 */
export function useLegacySafePush() {
  const { push } = useRouter()

  return function (url: string): void {
    if (process.env.NODE_ENV === 'production') {
      window.location.href = url
    } else {
      push(url)
    }
  }
}
