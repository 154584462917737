/**
 * Converts a value in metres to kilometres.
 *
 * @param {number} number - The value in metres to be converted.
 * @returns {number} - The value converted to kilometres.
 */
export const metresToKilometres = (number: number) => {
  return number / 1000
}

/**
 * Converts a value in kilometres to metres.
 *
 * @param {number} number - The value in kilometres to be converted.
 * @returns {number} - The value converted to metres.
 */
export const kilometresToMetres = (number: number) => {
  return number * 1000
}

/**
 * Converts a boolean value to its binary representation (1 or 0).
 *
 * @param {boolean} boolValue - The boolean value to be converted.
 * @returns {number} - 1 if the value is true, 0 if the value is false.
 */
export const boolToBinary = (boolValue: boolean) => {
  return boolValue ? 1 : 0
}
