/**
 * We disable the no-control-regex eslint rule
 * here, because the purpose of this function is
 * to sanitize cookies of non-printing characters,
 * including control characters.
 *
 * Implemented under ticket: KJCA-1797
 */
export const sanitizeCookies = (cookieString: string) =>
  // eslint-disable-next-line no-control-regex
  cookieString.replace(/[\x00-\x1F\x7F-\x9F]/g, '')
