import {
  type UserLocation,
  useGetLocationFromIdLazyQuery,
  useGetUserLocationQuery,
} from '@kijiji/generated/graphql-types'
import { useCallback } from 'react'

import { DEFAULT_USER_LOCATION } from '@/constants/location'
import { convertLocationToLatestSearchCookie } from '@/domain/location/convertLocationToLatestSearchCookie'
import {} from '@/domain/location/getRecentLocationsFromLocalStorage'
import { setRadiusBoostCookie } from '@/domain/location/setRadiusBoostCookie'
import { updateLatestSearchCookie } from '@/utils/cookies/latestSearchCookie'
import { updateUserLocationCache } from '@/utils/location/location'

export const useGetLocation = (): {
  location: UserLocation
  updateUserLocation: (newLocation: UserLocation) => void
  updateUserLocationById: (id: number) => void
} => {
  const { data: { userLocation } = {}, client } = useGetUserLocationQuery()
  const [fetchLocation] = useGetLocationFromIdLazyQuery({ fetchPolicy: 'cache-first' })

  const location = userLocation || DEFAULT_USER_LOCATION

  const updateUserLocation = useCallback(
    (newLocation: UserLocation) => {
      /** Update Cache */
      updateUserLocationCache(newLocation, client)

      /** Update cookies */
      const legacyCookieLocation = convertLocationToLatestSearchCookie(newLocation)
      updateLatestSearchCookie(legacyCookieLocation)

      /** Should reset local storage value if user change location to a region*/
      location.isRegion && setRadiusBoostCookie(false)
    },
    [client, location.isRegion]
  )

  const updateUserLocationById = async (id: number) => {
    const { data } = await fetchLocation({
      variables: { locationId: id },
    })

    if (!data?.location?.name) return

    const newLocation: UserLocation = {
      id: data.location.id,
      isRegion: true,
      name: data.location.name,
      area: null,
    }

    updateUserLocation(newLocation)
  }

  return { location, updateUserLocation, updateUserLocationById }
}
