const MILLISECONDS = 1
export const SECOND_IN_MS: number = MILLISECONDS * 1000
export const MINUTE_IN_MS: number = SECOND_IN_MS * 60
export const HOUR_IN_MS: number = MINUTE_IN_MS * 60
export const DAY_IN_MS: number = HOUR_IN_MS * 24
export const WEEK_IN_MS: number = DAY_IN_MS * 7
export const YEAR_IN_MS: number = DAY_IN_MS * 365

export const secondsToMs = (seconds?: number) => (seconds ? seconds * SECOND_IN_MS : undefined)

export const minutesFromMilliseconds = (minutesInMilliseconds: number) =>
  Math.ceil(minutesInMilliseconds / MINUTE_IN_MS)

export const hoursFromMilliseconds = (hoursInMilliseconds: number) =>
  Math.floor(hoursInMilliseconds / HOUR_IN_MS)
