import { ThemeProps } from '@kijiji/theme'
import { HTMLAttributes } from 'react'

import { StyledAs } from '@/ui/typings/helpers'

import { FlexCoreAttributes } from './helpers'
import { FlexContainer } from './styled'

export type FlexProps = {
  as?: StyledAs
  media?: { [x in keyof ThemeProps['breakpoints']]?: FlexCoreAttributes }
} & FlexCoreAttributes &
  HTMLAttributes<HTMLDivElement>

/**
 * @description Helper component to help compose layouts.
 * It takes all possible attributes defined for a flex-box block.
 * It will take different breakpoints.
 */

export const Flex = ({ children, media, ...rest }: FlexProps) => (
  <FlexContainer $media={media} {...rest}>
    {children}
  </FlexContainer>
)
