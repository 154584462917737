import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

import { FlexProps } from './Flex'
import { flex } from './helpers'

type FlexContainerProps = Omit<FlexProps, 'media'> & {
  $media?: FlexProps['media']
}

export const FlexContainer = styled.div<FlexContainerProps>(
  ({
    alignItems,
    alignSelf,
    flexDirection,
    flexWrap,
    gap,
    columnGap,
    rowGap,
    justifyContent,
    flexGrow,
    flexShrink,
    flexBasis,
    $media,

    theme,
  }) => `
  display: flex;

  ${flex({
    alignItems,
    alignSelf,
    flexDirection,
    flexWrap,
    justifyContent,
    gap,
    columnGap,
    rowGap,
    flexGrow,
    flexShrink,
    flexBasis,
  })}

  ${
    $media?.small
      ? `${MEDIA_QUERIES(theme).small}{ ${flex({ ...$media.small })} }`
      : ''
  }
  ${
    $media?.medium
      ? `${MEDIA_QUERIES(theme).medium}{ ${flex({ ...$media.medium })} }`
      : ''
  }
  ${
    $media?.large
      ? `${MEDIA_QUERIES(theme).large}{ ${flex({ ...$media.large })} }`
      : ''
  }
  ${
    $media?.xLarge
      ? `${MEDIA_QUERIES(theme).xLarge}{ ${flex({ ...$media.xLarge })} }`
      : ''
  }
`
)
