import { theme } from '@kijiji/theme'
import { css } from 'styled-components'

export const TRANSITION_STYLE = css`
  transition: all 0.3s ease-in-out;
`

export const KEYBOARD_ONLY_OUTLINE = css`
  *:focus:not(.focus-visible) {
    outline: none;
  }
`
/**
 * TODO: Update KEYBOARD_FOCUSED_OUTLINE.
 * Styles shouldn't directly import and use theme - This should come from the component provider as:
 * KEYBOARD_FOCUSED_OUTLINE = (theme: ThemeProps) => `...`
 */

// styles for overriding the default outline
export const KEYBOARD_FOCUSED_OUTLINE = `
  &:focus-visible {
    outline: none;
    border: 0.1rem solid ${theme.colors.blue.primary};
    border-radius: ${theme.borderRadius.small};
    filter: drop-shadow(0px 0px ${theme.spacing.mini} ${theme.colors.blue.primary});
    z-index: 1;
  }
`
