function generateAssetPrefix(environment, version) {
  if (environment === 'local' || process.env.CI) return undefined

  return `https://webapp-static.ca-kijiji-${generateEnvSuffix(
    environment
  )}.classifiedscloud.io/${version}`
}

function generateEnvSuffix(environment) {
  return environment === 'production' || environment === 'staging' ? environment : 'int'
}

module.exports = {
  generateAssetPrefix,
  generateEnvSuffix,
}
