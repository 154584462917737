import { ThemeProps } from '@kijiji/theme'
import { AnchorHTMLAttributes, forwardRef } from 'react'

import { StyledAs } from '@/ui/typings/helpers'

import { TextLinkBase } from './styled'

export type TextLinkProps = {
  /**
   * The type of element you want text to be rendered in
   * @default 'a'
   */
  as?: StyledAs
  /**
   * Defines if link should have underline text-decoration
   * @default false
   */
  hasUnderline?: boolean
  /**
   * Link size based on body typography definitions.
   */
  size?: keyof ThemeProps['typography']['body']
  /**
   * Defines style of the link
   * @default primary
   */
  variant?: 'primary' | 'secondary'
  /**
   * Link font weight
   * @default 'regular'
   */
  weight?: keyof ThemeProps['fontWeight']
} & AnchorHTMLAttributes<HTMLAnchorElement>

export const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>(
  (
    {
      as = 'a',
      hasUnderline = false,
      size = 'medium',
      variant = 'primary',
      weight = 'regular',
      ...rest
    }: TextLinkProps,
    ref
  ) => {
    return (
      <TextLinkBase
        as={as}
        $hasUnderline={hasUnderline}
        $size={size}
        $variant={variant}
        $weight={weight}
        ref={ref}
        {...rest}
      />
    )
  }
)
TextLink.displayName = 'TextLink'
