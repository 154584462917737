export const REGEX_PATTERN = {
  ATTRIBUTE_URL: /(a[0-9]{1,8})/g, // Regex to take the attribute from a string ex: a23l12
  LOCATION_URL: /(l[0-9]{1,8})/g, // Regex to take the location from a string ex: l23l12
  LOCATION_URL_ID_CAPTURE: /l(\d+)/, // Regex to take the location from a location slug string ex and capture the number: 147
  KEYWORD_URL: /(k[0-9]{1,8})/g, // Regex to take the keyword from a string ex: k0a23l12
  CATEGORY_URL: /(c[0-9]{1,8})/g, // Regex to take the category from a string ex: a23l0c132
  SRP_URL_DETAIL: /(^l|k|c|a)/, // Regex that only recognize the use of l, k, c, a.
  HAS_ONE_UPPERCASE: /^(?=.*[A-Z]).+$/, // accepts: at least one uppercase, denies: no uppercase
  HAS_ONE_LOWERCASE: /^(?=.*[a-z]).+$/, // accepts: at least one lowercase, denies: no lowercase
  HAS_A_NUMBER: /\d/, // accepts: at least one number, denies: no number
  HAS_A_SYMBOL: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, // accepts: at least one symbol, denies: no symbol
  ONLY_ALPHA: /^([^\d~`!@#$%^&*()_+=|\\}\]{[":;?/>.<,\- ']+[- ']?)+$/, // accepts: alpha, denies: numbers && symbols
  ONLY_ALPHA_NUMERIC: /^([^~`!@#$%^&*()_+=|\\}\]{[":;?/>.<,\- ']+[- ']?)+$/, // accepts: alpha && numbers, denies: symbols
  VALID_EMAIL: /^((?!\.)[\w\-_.+]*[^.\s])(@\w+)(\.\w+(\.\w+)?[^.\W])$/, // accepts: valid emails, denies: anything else
  IS_ENCODED_URL: /^[a-zA-Z0-9%.\-_~!'()*]+$/, // accepts encoded urls (don't contain '/' '?' '&' etc...)
  TOP_LEADERBOARD_ID: /^\w+-leaderboard-top-prebid$/, // accepts: top leaderboard id, denies: anything else
  KEYBOARD_ONLY_NUMBERS: /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/, // accepts: only numbers and Backspace | Tab | Delete | Left & Right arrow keyboard keys, denies: alphabets and symbols
  ONLY_NUMBERS: /^\d+$/, // accepts: only numbers, denies: alphabets and symbols
  VALID_PHONE: /^([0-9]{3})-([0-9]{3})-([0-9]{4})$/, // accepts: valid north american phone number with format: 123-456-7890, denies: anything else
  VALID_POSTAL_CODE: /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/, // accepts: valid canadian postal codes, denies: anything else
}
