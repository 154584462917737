import { type SearchResultsByUrlInput } from '@kijiji/generated/graphql-types'
import qs from 'query-string'

import { replaceEncodedWhitespaceWithPlusSign } from '@/domain/urls'

const extractPathAndQuery = (url: string) => {
  const regex = /https?:\/\/[^/]+(\/.*?)(\?.*)?$/
  const match = url.match(regex)
  return match ? match[1] + (match[2] || '') : null
}

export const sortAndShortenSrpUrl = (url: string) =>
  extractPathAndQuery(qs.stringifyUrl(qs.parseUrl(url)))

export const getSrpUrlCacheKey = (url: SearchResultsByUrlInput['url']) => {
  // ensures the URL cache key query string params are sorted alphabetically
  // saves shortened key like `/b-art-collectibles/canada/c12l0?ad=offer&condition=new&sort=dateDesc`
  const cacheKey = sortAndShortenSrpUrl(url.toString())
  return cacheKey && replaceEncodedWhitespaceWithPlusSign(cacheKey)
}
