export type SpacingKeys = keyof typeof spacing

export const spacing = {
  mini: '0.4rem',
  defaultSmall: '0.8rem',
  default: '1.6rem',
  large: '2.4rem',
  xLarge: '3.2rem',
  husky: '4rem',
  giant: '5.2rem',
}
