import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { Toggle } from '@/ui/atoms/toggle'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

/* Cookie Consent Drawer  */

export const CookieConsentContent = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xLarge};
  width: 100%;

  > p {
    max-width: 85rem;
  }

  ${MEDIA_QUERIES(theme).medium} {
    flex-direction: row;
  }
`
)

/** We have to set the height of the buttons to fit-content in order to counteract the
 * useragent stylesheet */
export const CookieConsentButtons = styled.div(
  ({ theme }) => `
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  gap: ${theme.spacing.default};
  justify-content: center;

  ${MEDIA_QUERIES(theme).medium} {
    align-self: flex-end;
    flex-direction: row;
    margin-left: auto;
  }
`
)

/* Cookie Consent Modal  */
export const CookieOptionWrapper = styled(Flex)`
  width: 100%;
`

export const AlwaysActiveLabel = styled(BodyText)`
  margin-left: auto;
  white-space: nowrap;
`

export const StyledToggle = styled(Toggle)`
  margin-left: auto;
  width: fit-content;
`

export const CookieConsentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.mini};
`

export const CookieOptionRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
  gap: ${({ theme }) => theme.spacing.mini};
`

export const AccordionWrapper = styled.div`
  grid-column: 1;
`

export const ControlWrapper = styled.div`
  grid-column: 2;
  padding-top: ${({ theme }) => theme.spacing.defaultSmall};
`
