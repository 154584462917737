/**
 * Deserializes a cookie string from an HTTP request header into an object of cookies.
 * This function splits the cookie string by '; ' to separate individual cookies, then further splits each cookie by '='
 * to extract the cookie name and value. Both the cookie name and value are decoded from URI component encoding.
 *
 * @param {string} cookieString - The cookie string from an HTTP request header, where cookies are separated by '; ' and each cookie is in the form of 'name=value'.
 * @returns {{ [key: string]: string }} An object where each key-value pair represents a cookie name and its corresponding value. If the input is not a valid non-empty string, an empty object is returned.
 */
export const deserializeCookies = (cookieString: string): { [key: string]: string } => {
  if (!cookieString || typeof cookieString !== 'string') {
    return {}
  }

  const cookies: { [key: string]: string } = {}
  const cookiePairs = cookieString.split('; ')

  for (const cookiePair of cookiePairs) {
    const [cookieName, cookieValue] = cookiePair.split('=')
    if (cookieName) {
      cookies[decodeURIComponent(cookieName)] = decodeURIComponent(cookieValue || '')
    }
  }

  return cookies
}
