import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgDataCharts: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#363373">
        <path d="M16.92.078H1.541A1.154 1.154 0 00.393 1.223v11.41a1.146 1.146 0 001.148 1.145h6.042v1.903H5.334a1.01 1.01 0 00-1.007 1.003v1.012h9.807v-1.012a1.005 1.005 0 00-1.007-1.003h-2.249v-1.903h6.042a1.154 1.154 0 001.149-1.145V1.223A1.146 1.146 0 0016.92.078zm-3.302 16.606v.498H4.843v-.498a.489.489 0 01.491-.489h7.793a.492.492 0 01.49.49zm-3.256-2.893v1.885H8.1v-1.885zm7.19-1.162a.63.63 0 01-.632.63H1.541a.634.634 0 01-.632-.63V1.223a.63.63 0 01.632-.63h15.38a.635.635 0 01.632.63z" />
        <path d="M10.662 3.998h-.91a.525.525 0 00-.521.52v6.77a.522.522 0 00.522.52h.91a.525.525 0 00.521-.52v-6.77a.522.522 0 00-.522-.52zm0 7.29h-.91V4.512h.91v.006zM13.094 7.211h-.91a.525.525 0 00-.522.52v3.554a.522.522 0 00.522.52h.91a.525.525 0 00.522-.52v-3.55a.522.522 0 00-.522-.524zm0 4.074h-.91v-3.55h.91zM15.525 5.617h-.91a.525.525 0 00-.522.518v5.15a.523.523 0 00.522.521h.91a.526.526 0 00.522-.52V6.135a.522.522 0 00-.522-.518zm.006 5.671h-.916-.003-.003V6.135l.003-.001h.919zM7.113 10.044H3.16a.741.741 0 00-.738.736v.293a.737.737 0 00.738.736h3.952a.742.742 0 00.739-.736v-.293a.737.737 0 00-.74-.736zm.223 1.029a.224.224 0 01-.223.221H3.16a.226.226 0 01-.222-.221v-.293a.225.225 0 01.222-.22h3.952a.226.226 0 01.223.222zM5.27 2.1a2.85 2.85 0 00-2.63 1.747 2.827 2.827 0 00.612 3.091 2.847 2.847 0 004.858-2 2.83 2.83 0 00-.83-2.004A2.848 2.848 0 005.27 2.1zm2.29 2.46l-2.138.091-.803-1.943a2.325 2.325 0 012.587.945c.181.273.302.582.354.906zM4.143 2.904l.813 1.97L3.448 6.38a2.313 2.313 0 01.695-3.475zM5.27 7.257a2.32 2.32 0 01-1.46-.512l1.58-1.578 2.193-.095a2.318 2.318 0 01-.723 1.549c-.43.406-.998.633-1.59.636z" />
      </g>
    </Icon>
  )
}
export default SvgDataCharts
