import {
  type Client,
  type OptimizelyDecision,
  type OptimizelyUserContext,
  type UserAttributes,
  createInstance,
  errorHandler,
  setLogLevel,
} from '@optimizely/optimizely-sdk'

import { type FeatureFlag } from '@/lib/optimizely'
import { OptimizelyClient } from '@/lib/optimizely/OptimizelyClient'
import { sendToLogger } from '@/utils/sendToLogger'

/**
 * `OptimizelyServerManager` is a singleton class responsible for interacting with the Optimizely SDK.
 * This manager abstracts various Optimizely functionalities, such as initializing the client and
 * making decisions based on feature flags. It ensures that there is only one instance of the
 * Optimizely client throughout the application lifecycle.
 *
 * Usage:
 * 1. Retrieve the instance by calling `OptimizelyServerManager.getInstance()`.
 * 2. Use the `decide` method to check the status of feature flags for a particular user context.
 *
 * Example:
 * ```typescript
 * const optimizelyManager = OptimizelyServerManager.getInstance();
 * const decisionResult = await optimizelyManager.decide('feature-key', 'user123');
 * ```
 */
class OptimizelyServerManager {
  private static instance: OptimizelyServerManager
  private optimizelyClient: Client | null = null
  private userContext: OptimizelyUserContext | null = null // Adjust this type based on your user context
  private logLevel: string
  private constructor() {
    this.initializeOptimizely()
    this.logLevel = ''
  }

  /**
   * Retrieves the shared OptimizelyClient instance.
   * Creates a new instance if it doesn't exist.
   * @example
   * const instance = OptimizelyClient.getInstance();
   */
  public static getInstance(): OptimizelyServerManager {
    if (!OptimizelyServerManager.instance) {
      OptimizelyServerManager.instance = new OptimizelyServerManager()
    }
    return OptimizelyServerManager.instance
  }

  /**
   * Retrieves the shared OptimizelyClient instance.
   * Creates a new instance if it doesn't exist.
   * @example
   * const instance = OptimizelyClient.getInstance();
   */
  public static getClient() {
    return this.getInstance().optimizelyClient
  }

  /**
   * Initializes the Optimizely client.
   *
   * This method attempts to create an instance of the Optimizely client using the SDK key
   * provided in the environment variables. It's a crucial step before making any
   * Optimizely feature flag or experiment decisions. The method should be called
   * during the instantiation of the `OptimizelyServerManager` class, ensuring
   * that the Optimizely client is ready for use in subsequent calls.
   *
   * Usage of an environment variable for the SDK key abstracts the key from the codebase,
   * enhancing security and flexibility, as the same code can be used across multiple
   * environments with different SDK keys.
   *
   * If the instance creation fails (typically due to issues with the SDK key or network
   * connectivity problems), an error will be logged to the console. This method does
   * not throw, so the class instantiation process remains uninterrupted, but subsequent
   * Optimizely-related calls may fail due to the absence of a valid client instance.
   *
   * @private
   * @returns {void} Nothing. The result of this operation is the setting of the `optimizelyClient` property.
   */
  private initializeOptimizely(): void {
    try {
      this.setOptimizelyLogLevel()
      this.optimizelyClient = createInstance({
        sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK || '',
        errorHandler,
      })
    } catch (error) {
      sendToLogger('Failed to initialize Optimizely', {
        tags: { component: 'OptimizelyServerManager', fn: 'initializeOptimizely' },
        fingerprint: ['initializeOptimizely'],
      })
    }
  }

  private setOptimizelyLogLevel(): void {
    if (process.env.NODE_ENV === 'production') {
      this.logLevel = 'error'
    } else if (process.env.NEXT_PUBLIC_OPTIMIZELY_LOG_MODE) {
      this.logLevel = process.env.NEXT_PUBLIC_OPTIMIZELY_LOG_MODE
    }

    setLogLevel(this.logLevel)
  }

  /**
   * Decides all or whichever optimizely feature flags specified and converts them to a string
   *
   * @example
   * OptimizelyClient.decideTestGroupWithUserContext() // returns "KCAN1234=0;KCAN5678=1"
   */
  public decideTestGroupWithUserContext = (
    user: {
      id?: string
      attributes?: UserAttributes
    },
    flags?: Array<FeatureFlag>
  ): string => {
    const userContext =
      (user.id && this.optimizelyClient?.createUserContext(user.id, user.attributes)) || null

    if (!userContext) {
      return ''
    }

    const decisions = flags
      ? Object.values(userContext.decideForKeys(flags))
      : Object.values(userContext.decideAll())

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore slight difference in decisions type which won't affect method functionality
    return OptimizelyClient.transformDecisionString(decisions)
  }

  public async decide(
    featureKey: string,
    userId: string,
    attributes?: UserAttributes
  ): Promise<Omit<OptimizelyDecision, 'userContext'>> {
    if (!this.optimizelyClient) {
      return fallBackDecision(featureKey)
    }

    try {
      await this.optimizelyClient.onReady()
      this.userContext = this.optimizelyClient.createUserContext(userId, attributes)

      if (!this.userContext) {
        return fallBackDecision(featureKey)
      }

      const decision = this.userContext.decide(featureKey)
      return decision
    } catch (error) {
      //An error occurred when making a decision
      return fallBackDecision(featureKey)
    }
  }
}

export default OptimizelyServerManager

/**
 * Returns a fallback decision object for when the Optimizely client is not available.
 */
const fallBackDecision = (key: string) => ({
  enabled: false,
  variationKey: null,
  variables: {},
  ruleKey: null,
  flagKey: key,
  reasons: [],
})
