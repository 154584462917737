import { type LinkProps } from 'next/link'
import { useTranslation } from 'next-i18next'
import React from 'react'

import {
  LinkCustomExternalStyled,
  LinkCustomStyled,
  LinkTargetDescription,
} from '@/components/shared/link-custom/styled'
import { type TextLinkProps } from '@/ui/atoms/text-link'

// Omit href from TextLinkProps as we want to use the href prop from Next.js Link
type OmittedTextLinkProps = Omit<TextLinkProps, 'href'>

// Exclude target="_blank" from TextLinkProps
type ExcludeTargetBlank<T> = T extends { target?: '_blank' | infer R } ? T & { target?: R } : T

// Exclude passHref and legacyBehavior from LinkProps
type CustomLinkProps = Omit<LinkProps, 'passHref' | 'legacyBehavior'>

export type BaseLinkCustomProps = ExcludeTargetBlank<
  OmittedTextLinkProps & {
    /**
     * The URL the link should point to. If wrap by `<Trans`, use the `noChildren` prop.
     */
    href: string
    /**
     * Whether the link should be rendered as a button.
     */
    button?: boolean
    /**
     * Whether the link should open in a new tab (adds `target="_blank"` and `rel="noopener noreferrer"`)
     */
    openInNewTab?: boolean
    /**
     * Whether the link should be full width (used when it's a button)
     */
    isFullWidth?: boolean
    /**
     * Bypass any preset styles
     * This should be used when we are wrapping a listing or a component that is not a text link
     */
    noStyle?: boolean
  } & Partial<CustomLinkProps>
>

type LinkCustomPropsWithChildren = BaseLinkCustomProps & {
  noChildren?: false
  children: React.ReactNode
}

type LinkCustomPropsWithoutChildren = BaseLinkCustomProps & {
  /**
   * Used when children are passed as props to the component. Example is when wrapping with <Trans.
   */
  noChildren: true
  children?: React.ReactNode
}

export type LinkCustomProps = LinkCustomPropsWithChildren | LinkCustomPropsWithoutChildren

/**
 * `LinkCustom` is a component that renders a link, which can be either internal or external. Legacy pages on kijiji.ca should be considered external as they are not part of the Nextjs app and should not be using the Link from Nextjs.
 *
 * The component decides whether to render an external or internal link based on the `href` prop. External links (those starting with 'http', 'https', or '//') are rendered with `LinkCustomExternalStyled`, while internal links are handled using Next.js's `Link` component with `LinkCustomStyled`.
 *
 * The component uses `React.forwardRef` to forward a ref to the underlying anchor element.
 *
 * @param props - Props for the LinkCustom component.
 * @param ref - Ref forwarded to the anchor element.
 * @returns A styled link element based on the provided props.
 *
 * @example
 * <LinkCustom href="/about" button={true}>
 *   About Us
 * </LinkCustom>
 */

const LinkCustom = React.forwardRef<HTMLAnchorElement, LinkCustomProps>(
  ({ href, openInNewTab, children, noChildren, noStyle, ...rest }, ref) => {
    const { t } = useTranslation('common')

    /**
     * It will be a legacy link if the page hasn't been migrated to NWA
     * This check is necessary to prevent external links to use the next/link and
     * hit our servers with a unnecessary prefetch
     *
     * NWA_PROD_READY_REWRITES
     */
    const isLegacyLink =
      typeof href === 'string' &&
      !href.includes('/b-') && // SRP
      !href.includes('/h-') && // Homepage
      href !== '/' // Homepage

    // Check if the link is an external link
    const isExternalLink =
      typeof href === 'string' &&
      (href.startsWith('http') || href.startsWith('https') || href.startsWith('//'))

    if (isExternalLink || isLegacyLink) {
      return (
        <LinkCustomExternalStyled
          href={href}
          target={openInNewTab ? '_blank' : undefined}
          rel={openInNewTab ? 'noopener noreferrer' : undefined}
          ref={ref}
          noStyle={noStyle}
          {...rest}
        >
          {children}
          {openInNewTab ? (
            <LinkTargetDescription>{t('link.label.new_tab')}</LinkTargetDescription>
          ) : null}
        </LinkCustomExternalStyled>
      )
    }

    // For internal links, we are removing the Next.js Link because of prefetch issues
    /**
     * Next/Links are prefetching links even when prefetch is false
     * This issue is causing our requests to blow up.
     *
     * We will consider returning to use Next/Links once we migrate to use app routes, as it seems the issue was resolved then.
     * Before using Next/Links or prefetch again, make sure to check the middleware behaviour and if any cookies are getting updated on prefetch.
     * */
    return (
      <LinkCustomStyled
        target={openInNewTab ? '_blank' : undefined}
        href={href}
        ref={ref}
        {...rest}
      >
        {children}

        {openInNewTab ? (
          <LinkTargetDescription>{t('link.label.new_tab')}</LinkTargetDescription>
        ) : null}
      </LinkCustomStyled>
    )
  }
)

LinkCustom.displayName = 'LinkCustom'

export { LinkCustom }
