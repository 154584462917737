import { HTMLAttributes } from 'react'

import { LoadingContainer } from './styled'

export type LoadingProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Defines the size of the dots
   * @default small
   */
  size?: 'small' | 'medium'
  /**
   * Defines color of loading. Usually defines based on the background this component is placed in.
   * @default primary
   */
  variant?: 'light' | 'primary' | 'secondary'
}

/**
 * @description Animated loading dots
 */
export const Loading = ({
  variant = 'primary',
  size = 'small',
  ...rest
}: LoadingProps) => (
  <LoadingContainer
    $size={size}
    $variant={variant}
    aria-live="polite"
    data-testid="loading"
    {...rest}
  >
    <div />
    <div />
    <div />
  </LoadingContainer>
)
