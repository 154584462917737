import { ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

import { spacing, SpacingCoreAttributes } from './helpers'

export type SpacingProps = SpacingCoreAttributes & {
  [x in keyof ThemeProps['breakpoints']]?: SpacingCoreAttributes
} & {
  fullWidth?: boolean
}

/**
 * Spacing Component
 *
 * @description Defines the margin/padding around components.
 */

export const Spacing = styled.div<SpacingProps>(
  ({
    theme,
    m,
    mBottom,
    mLeft,
    mRight,
    mTop,
    p,
    pTop,
    pBottom,
    pLeft,
    pRight,
    small,
    medium,
    large,
    xLarge,
    fullWidth,
  }) => `
  width: ${fullWidth ? '100%' : 'initial'};

  ${spacing({
    m,
    mBottom,
    mLeft,
    mRight,
    mTop,
    p,
    pTop,
    pBottom,
    pLeft,
    pRight,
  })}

  ${MEDIA_QUERIES(theme).small}{
    ${spacing(small)}
  }

  ${MEDIA_QUERIES(theme).medium}{
    ${spacing(medium)}
  }

  ${MEDIA_QUERIES(theme).large}{
    ${spacing(large)}
  }

  ${MEDIA_QUERIES(theme).xLarge}{
    ${spacing(xLarge)}
  }
`
)
