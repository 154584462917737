import styled from 'styled-components'

export const PlusMinusContainer = styled.div<{ isActive: boolean }>(
  ({ isActive, theme }) => `
    position: relative;
    width: ${theme.spacing.default};
    height: ${theme.spacing.default};
    cursor: pointer;
    flex-shrink: 0;

    &:before,
    &:after {
      content: "";
      display: block;
      background-color: ${theme.colors.grey.primary};
      position: absolute;
      top: 50%;
      left: 0;
      transition: 0.35s;
      width: 100%;
      height: 0.1rem;
    }

    &:before {
      transform: translateY(-50%) ${isActive && `rotate(-90deg)`};
      opacity: ${isActive ? '0' : '1'};
    }

    &:after {
      transform: translateY(-50%) ${isActive ? `rotate(0)` : `rotate(90deg)`};
    }
  `
)
