import { type DynamicLinkParameters } from '@/types/appPenetrationCampaign'

export const APP_PEN_DYNAMIC_LINK_PARAMETERS: DynamicLinkParameters = {
  realEstate: {
    android: {
      apn: 'com.ebay.kijiji.ca',
      utm_campaign: 'APP_PEN_MAP_MODULE_BANNER_CTA',
      utm_medium: 'onsite',
      utm_source: 'Kijiji',
      efr: 1,
      layout: 'MAP',
    },
    ios: {
      ct: 'APP_PEN_MAP_MODULE_BANNER_CTA',
      ibi: 'com.kijiji.iphone',
      isi: 318979520,
      mt: 8,
      pt: 251500,
      efr: 1,
      layout: 'MAP',
    },
  },
  registration: {
    android: {
      apn: 'com.ebay.kijiji.ca',
      utm_campaign: 'APP_PEN_REGISTRATION_BANNER_CTA',
      utm_medium: 'onsite',
      utm_source: 'Kijiji',
      efr: 1,
    },
    ios: {
      ct: 'APP_PEN_REGISTRATION_BANNER_CT',
      ibi: 'com.kijiji.iphone',
      isi: 318979520,
      mt: 8,
      pt: 251500,
      efr: 1,
    },
  },
}
