import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useEffect } from 'react'

import { SYSTEM_MESSAGES } from '@/constants/systemMessages'
import { useSignin } from '@/features/auth/hooks/useSignin'
import { useLegacySafePush } from '@/hooks/useLegacySafePush'

interface Props {
  children: React.ReactNode
}

const AuthenticationGuard = ({ children }: Props) => {
  const { status, data: session } = useSession()
  const { query, pathname } = useRouter()
  const push = useLegacySafePush()
  const isLoading = status === 'loading'
  const isAuthenticated = !isLoading && status === 'authenticated'
  const ula = query[SYSTEM_MESSAGES.ula.key] === 'true'

  useEffect(() => {
    if (ula) {
      push(`/?${SYSTEM_MESSAGES.ula.key}=true`)
    }
  }, [push, ula])

  const shouldSignin = () => !isLoading && !isAuthenticated && !ula
  const shouldTryRefresh = () => session?.user.error === 'RefreshAccessTokenError'

  useSignin({
    callbackUrl: pathname,
    goToSignIn: shouldSignin(),
    tryRefresh: shouldTryRefresh(),
  })

  if (!isLoading && session) {
    return <>{children}</>
  }

  return null
}

export default AuthenticationGuard
