import { CookieRegistry } from '@/constants/cookieRegistry'
import { createCookie } from '@/utils/cookies/createCookie'
import { deleteCookie } from '@/utils/cookies/deleteCookie'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'
import { sanitizeCookies } from '@/utils/cookies/sanitizeCookies'
import { isServer } from '@/utils/isSSR'
import { sendToLogger } from '@/utils/sendToLogger'

interface CookiePreferences {
  optOutTargeting: boolean
  optOutAnalyticsAndPerformance: boolean
}

const isValidCookiePreferencesMinified = (obj: Record<string, string>): boolean => {
  // Ensure obj is a non-null object
  if (typeof obj !== 'object' || obj == null) {
    return false
  }
  const { t, a } = obj

  // Check if the properties 't' and 'a' exist and have valid values
  return (t === 'y' || t === 'n') && (a === 'y' || a === 'n')
}

const useCookiePreferences = () => {
  const getCookiePreferences = (): CookiePreferences | null => {
    const cookieValue = isServer()
      ? undefined
      : getCookieByKey(document?.cookie, CookieRegistry.COOKIE_PREFERENCES)

    if (!cookieValue) return null

    try {
      const decoded = decodeURIComponent(cookieValue)
      const sanitized = sanitizeCookies(decoded)
      const deserialized = JSON.parse(sanitized)

      if (isValidCookiePreferencesMinified(deserialized)) {
        return {
          optOutTargeting: deserialized.t === 'n',
          optOutAnalyticsAndPerformance: deserialized.a === 'n',
        }
      }
    } catch (e) {
      sendToLogger(e, {
        tags: { fn: 'useCookiePreferences' },
        fingerprint: ['JsonParse'],
        extra: { value: `cookie: ${cookieValue}` },
      })
    }

    deleteCookie(CookieRegistry.COOKIE_PREFERENCES, '')
    return null
  }

  const setCookiePreferences = (cookiePreferences: CookiePreferences) => {
    const { optOutAnalyticsAndPerformance, optOutTargeting } = cookiePreferences

    const cookiePreferencesMinified = {
      t: optOutTargeting ? 'n' : 'y',
      a: optOutAnalyticsAndPerformance ? 'n' : 'y',
    }

    const serialized = JSON.stringify(cookiePreferencesMinified)
    const encoded = encodeURIComponent(serialized)
    createCookie(CookieRegistry.COOKIE_PREFERENCES, encoded, { expiryInDays: 365 })

    // Reload the page to remove any opted out scripts & cookies, this is how most sites do it
    window.location.reload()
  }

  const hasUserOptedOutAnalytics = getCookiePreferences()?.optOutAnalyticsAndPerformance ?? false
  const hasUserOptedOutTargeting = getCookiePreferences()?.optOutTargeting ?? false

  return {
    hasUserOptedOutAnalytics,
    hasUserOptedOutTargeting,
    getCookiePreferences,
    setCookiePreferences,
  }
}

export default useCookiePreferences
