import { type GaSaveSearchType } from '@/hooks/useSaveSearchActions'

export const GA_CTA_HEADER = 'cta="header"'

// This is to differentiate between events from legacy, which have PLATFORM_VERSION set to "Wildebeest"
export const PLATFORM_VERSION = 'next-web-app'

//The below object declares the ad types and its GA equivalent values which is use for tracking purposes
export const GA_AD_TYPE_VALUE = {
  TOP_AD: 'topad',
  ZIP: 'partner_zip',
  ORGANIC: 'organic',
  CAS: 'partner_cas',
  PROV_TOP_AD: 'p_topad',
} as const

export const isKeyOfGAAdType = (value: string): value is keyof typeof GA_AD_TYPE_VALUE =>
  typeof Object.keys(GA_AD_TYPE_VALUE).find((key) => key === value) !== 'undefined'

export const isGaSaveSearchType = (type: string | string[]): type is GaSaveSearchType =>
  (type as GaSaveSearchType) === 'fab' || (type as GaSaveSearchType) === 'toggle'

//The below object dictates the badge names and its GA equivalent values which is use for tracking purposes
export const BADGE_TYPE_GA_VALUE = {
  featured: '001',
  reduced: '002',
  urgent: '003',
  virtualTour: '004',
  highlight: '005',
  showcase: '006',
  hasPriceDrop: '007',
}

export const GA_SAVE_SEARCH_TYPES = {
  toggle: 'toggle',
  floatingActionButton: 'fab',
} as const
