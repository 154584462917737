import { HTMLAttributes } from 'react'

import { XOR } from '@/ui/typings/helpers'

import { ToggleButton, ToggleComponent } from './styled'

/* Either a label or an aria-label is required. Not both. */
type LabelRequired = {
  /**
   * Toggle label
   */
  label: string
  /**
   * Aria label
   */
  ariaLabel?: string
}

type AriaLabelRequired = {
  /**
   * Toggle label
   */
  label?: string
  /**
   * Aria label
   */
  ariaLabel: string
}

type CommonToggleProps = HTMLAttributes<HTMLButtonElement> & {
  /**
   * Margin bottom to component
   */
  bottom?: string
  /**
   * Defines if toggle is active or not
   * @default false
   */
  isActive?: boolean
  /**
   * Defines label position in reference of toggle
   * @default left
   */
  labelPosition?: 'right' | 'left'
}

/* We constrain the type such that either the label or the aria-label will be required, but not both */
export type ToggleProps = CommonToggleProps &
  XOR<LabelRequired, AriaLabelRequired> & {
    isFullWidth?: boolean
  }

/**
 * @description Checkbox input that looks like a toggle switch
 * @note Accessibility concern: ON and OFF label should be added to design to have more than just color defining if the toggle is on or off.
 */

export const Toggle = ({
  bottom = '0',
  isActive = false,
  label,
  ariaLabel,
  labelPosition = 'left',
  ...rest
}: ToggleProps) => {
  const optionalAriaLabel = ariaLabel && { 'aria-label': ariaLabel }

  return (
    <ToggleButton
      $bottom={bottom}
      $labelPosition={labelPosition}
      aria-pressed={isActive}
      as="button"
      type="button"
      {...optionalAriaLabel}
      {...rest}
    >
      {label || ''}
      <ToggleComponent />
    </ToggleButton>
  )
}
