export type Task = () => void

class TaskQueue {
  queue: { task: Task; priority: number }[]
  isProcessing: boolean

  constructor() {
    this.queue = []
    this.isProcessing = false
  }

  // higher priority numerical value = lower priority in processing
  addTask(task: Task, priority = 1) {
    this.queue.push({ task, priority })
    this.queue.sort((a, b) => a.priority - b.priority) // Sort tasks by priority
  }

  processQueue() {
    if (this.isProcessing || this.queue.length === 0) return

    this.isProcessing = true

    const processTask = () => {
      const queueItem = this.queue.shift()

      if (queueItem) {
        queueItem.task()

        if (this.queue.length > 0) {
          processTask()
        } else {
          this.isProcessing = false
        }
      } else {
        this.isProcessing = false
      }
    }

    processTask()
  }
}

export default TaskQueue
