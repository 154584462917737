import { isServer } from '@/utils/isSSR'

const useAnvilDirectly = process.env.NEXT_PUBLIC_DEV_SSL === 'true'

export const getAnvilUrl = () => {
  if (isServer()) return `${process.env.NEXT_PUBLIC_SERVER_ANVIL_URL}/api`

  if (useAnvilDirectly) return `${process.env.NEXT_PUBLIC_CLIENT_ANVIL_URL}/api`

  return `${process.env.NEXT_PUBLIC_WEBAPP_URL}/anvil/api`
}
