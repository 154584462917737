import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCommunityGroup: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#373373">
        <path d="M2.499 8.664c.32.215.684.371 1.07.458.388.086.79.102 1.183.046 1.066-.147 2.034-.884 2.439-1.843.601.346 1.297.533 2.008.539s1.411-.168 2.02-.503c.408.94 1.366 1.662 2.418 1.807.394.055.795.038 1.182-.049s.75-.241 1.07-.456c.321-.215.592-.485.798-.795.206-.31.343-.654.403-1.012.06-.359.042-.724-.054-1.076a2.654 2.654 0 00-.501-.975 2.947 2.947 0 00-.874-.726 3.228 3.228 0 00-1.112-.366 2.88 2.88 0 00-1.525.161c-.134-.827-.59-1.583-1.286-2.13S10.154.897 9.235.897s-1.807.3-2.502.847c-.696.547-1.152 1.303-1.286 2.13a2.97 2.97 0 00-1.602-.157 3.165 3.165 0 00-1.586.69 2.724 2.724 0 00-.9 1.375 2.525 2.525 0 00.09 1.598c.203.513.568.96 1.05 1.284zm11.968-4.487c.342.029.674.12.976.27s.566.355.777.6c.212.247.366.53.453.832.087.303.105.618.053.927-.051.309-.172.605-.354.87s-.421.494-.704.671a2.657 2.657 0 01-.938.363c-.337.061-.684.06-1.02-.006-.903-.124-1.727-.764-2.048-1.592a.242.242 0 00-.016-.03 3.549 3.549 0 001.043-1.206c.245-.467.371-.979.37-1.497.43-.212.928-.283 1.408-.202zM9.231 1.363a3.55 3.55 0 011.84.509c.545.331.97.802 1.22 1.353.251.551.317 1.157.19 1.742a2.942 2.942 0 01-.908 1.544 3.426 3.426 0 01-1.696.826 3.618 3.618 0 01-1.914-.172c-.606-.228-1.123-.615-1.487-1.11s-.559-1.08-.559-1.676c.001-.8.35-1.566.972-2.131s1.463-.884 2.342-.885zm-6.96 3.72a2.43 2.43 0 01.724-.603c.282-.156.596-.259.923-.303a2.348 2.348 0 011.485.2c-.001.509.12 1.011.356 1.472s.578.869 1.006 1.196a.26.26 0 00-.042.067C6.4 7.94 5.578 8.58 4.67 8.704a2.743 2.743 0 01-1.441-.196 2.453 2.453 0 01-1.08-.889 2.135 2.135 0 01-.36-1.284c.021-.451.188-.887.48-1.252z" />
        <path d="M13.923 9.97h-.106a2.685 2.685 0 00-.84.084 4.872 4.872 0 00-1.679-1.176 5.306 5.306 0 00-4.148.004 4.866 4.866 0 00-1.685 1.188 2.623 2.623 0 00-.898-.1h-.105c-1.073-.004-2.104.38-2.866 1.067-.762.688-1.193 1.622-1.199 2.598v2.349a.97.97 0 00.33.713c.209.189.492.294.786.294h4.403a.31.31 0 00.058-.007h6.437a.309.309 0 00.057.007h4.403c.295 0 .578-.105.787-.294a.97.97 0 00.329-.713v-2.349c-.006-.976-.437-1.91-1.199-2.598-.762-.687-1.793-1.07-2.865-1.067zM9.23 8.927c.643 0 1.278.129 1.86.377s1.097.611 1.508 1.061a.22.22 0 00.053.12c.03.035.07.061.115.076.553.683.851 1.51.85 2.359v3.058a.513.513 0 01-.172.378.62.62 0 01-.415.157H5.43a.62.62 0 01-.415-.157.513.513 0 01-.173-.378V12.92c.002-1.059.464-2.074 1.287-2.822.822-.749 1.937-1.17 3.1-1.171zM.915 15.98v-2.345c.006-.85.383-1.665 1.048-2.263s1.564-.933 2.499-.93h.107c.19-.008.38.004.567.035a4.153 4.153 0 00-.809 2.446v3.058c0 .194.061.384.177.546h-2.99a.65.65 0 01-.425-.16c-.055-.051-.1-.111-.13-.178s-.045-.137-.044-.209zm16.56 0a.523.523 0 01-.18.38.632.632 0 01-.42.158h-2.918a.94.94 0 00.177-.546v-3.059c0-.874-.285-1.73-.817-2.458.165-.02.332-.027.499-.022h.107a3.84 3.84 0 011.353.24c.43.16.82.395 1.15.692s.591.651.77 1.04c.18.39.273.808.275 1.23z" />
      </g>
    </Icon>
  )
}
export default SvgCommunityGroup
