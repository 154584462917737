import styled from 'styled-components'

import { type SharedButtonStyleProps, sharedButtonStyle } from '@/ui/atoms/button/styled'
import { TextLink } from '@/ui/atoms/text-link'

type LinkCustomProps = SharedButtonStyleProps & {
  button?: boolean
  noStyle?: boolean
}

export const LinkCustomStyled = styled(TextLink)<LinkCustomProps>`
  position: relative;

  ${({ theme, button, noStyle, $isFullWidth, $size, $shape, $variant, $gap }) => {
    if (noStyle)
      return `
      &:hover, &:focus, &:active {
        text-decoration: unset;
      }`

    if (button)
      return sharedButtonStyle({
        theme,
        $isFullWidth,
        $size,
        $shape,
        $variant,
        $gap,
      })

    return ''
  }}
`

export const LinkCustomExternalStyled = styled(TextLink)<LinkCustomProps>`
  position: relative;

  ${({ theme, button, noStyle, $isFullWidth, $size, $shape, $variant, $gap }) => {
    if (noStyle)
      return `
        &:hover, &:focus, &:active {
          text-decoration: unset;
        }
      `

    if (button)
      return sharedButtonStyle({
        theme,
        $isFullWidth,
        $size,
        $shape,
        $variant,
        $gap,
      })

    return ''
  }}
`

export const LinkTargetDescription = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.1rem;
`
