import { type NextApiRequestCookies } from 'next/dist/server/api-utils'

export const serializeCookies = (cookies: NextApiRequestCookies): string => {
  if (!cookies || typeof cookies !== 'object') {
    return ''
  }

  const cookieStrings: string[] = []

  for (const cookieName in cookies) {
    if (Object.prototype.hasOwnProperty.call(cookies, cookieName)) {
      const cookieValue = cookies[cookieName]
      const cookieString = cookieValue
        ? `${encodeURIComponent(cookieName)}=${encodeURIComponent(cookieValue)}`
        : ''
      cookieStrings.push(cookieString)
    }
  }

  return cookieStrings.join('; ')
}
