import { borderRadius } from './borderRadius'
import { boxShadow } from './boxShadow'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { spacing } from './spacing'
import { fontFamily, fontWeight, typography } from './typography'

export type ThemeProps = {
  borderRadius: typeof borderRadius
  boxShadow: typeof boxShadow
  breakpoints: typeof breakpoints
  colors: typeof colors
  fontFamily: typeof fontFamily
  fontWeight: typeof fontWeight
  spacing: typeof spacing
  typography: typeof typography
}

export const theme: ThemeProps = {
  borderRadius,
  boxShadow,
  breakpoints,
  colors,
  fontFamily,
  fontWeight,
  spacing,
  typography,
}
