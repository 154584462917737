import merge from 'deepmerge'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useCallback } from 'react'

import { type PAGE_TYPE } from '@/constants/pageTypes'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useLocale } from '@/hooks/useLocale'
import { buildDataLayer, clearDataLayer, pushToDataLayer, trackPageView, usePage } from '@/lib/ga'
import { useTrackOnceOnLoad } from '@/lib/ga/hooks/useTrackOnceOnLoad'

export type UseTrackingProps = {
  /**
   * Additional data to be added to the dataLayer
   */
  data?: Record<string, unknown>
  /**
   * Page type for Google Analytics.
   * Refer to https://wiki.es.ecg.tools/pages/viewpage.action?pageId=98439770
   */
  pageType: PAGE_TYPE
  /**
   * Determines whether or not we should clear the dataLayer, we may not want to in some instances
   * when manually calling the `reportPageview` function (ex. when changing data related to a filter)
   */
  shouldClearDataLayer?: boolean
  /**
   * Determines whether we should track the initial pageview, useful when we just want access
   * to the manual `reportPageview` function
   */
  shouldTrackInitial?: boolean
}

export const useTracking = ({
  data = {},
  pageType,
  shouldClearDataLayer = true,
  shouldTrackInitial = true,
}: UseTrackingProps) => {
  const { location } = useGetLocation()
  const { apiLocale } = useLocale()
  const { data: session } = useSession()
  const { asPath } = useRouter()
  const pageData = usePage({ pageType })

  const triggerTracking = useCallback(async () => {
    clearDataLayer()
    pushToDataLayer(
      await buildDataLayer({ session, additionalData: merge(pageData, data), apiLocale, location }),
      0
    )
    trackPageView(asPath)
  }, [session, pageData, data, apiLocale, location, asPath])

  useTrackOnceOnLoad({
    triggerTracking: triggerTracking,
    shouldTrack: shouldTrackInitial,
    shouldClearDataLayer,
  })
}
