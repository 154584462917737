import { type DialogProps } from '@reach/dialog'
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTheme } from 'styled-components'

import { ResponsiveProp } from '@/ui/typings/helpers'

import { ModalCloseButton } from './ModalCloseButton'
import {
  StyledModal,
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
} from './styled'

/**
 * Sport old modal definitions centralized in this Modal component
 */
export const ModalBody = StyledModalBody
export const ModalFooter = StyledModalFooter
export const ModalHeader = StyledModalHeader

type ModalProps = DialogProps & {
  /**
   * Aria-label of close button
   */
  closeButtonLabel?: string
  /**
   * Defines if the close button on the right-top side of the modal should appear
   */
  hasCloseButton?: boolean
  /**
   * Min-height definition of the modal
   * @default minWidth mobile 100vh / desktop fit-content
   */
  height?: ResponsiveProp<string>
  /**
   * Modal unique identifier
   * */
  id: string
  /**
   * Defines if the modal is white or dark
   * It will also adapt the close button
   * */
  isDarkModal?: boolean
  /**
   * Definition if the modal is opened
   * */
  isOpen: boolean
  /**
   * Aria label of the modal
   * */
  label: string
  /**
   * Defines the modal margins
   */
  margin?: ResponsiveProp<string>
  /**
   * Defines the max height of the modal
   */
  maxHeight?: ResponsiveProp<string>
  /**
   * Defines the max width of the modal
   */
  maxWidth?: ResponsiveProp<string>
  /**
   * Trigger to close the modal
   * */
  onCancel: () => void
  /**
   * Padding around modal
   */
  padding?: ResponsiveProp<string>
  /**
   * Min-width definition of the modal
   * @default minWidth mobile 100vw / desktop fit-content
   */
  width?: ResponsiveProp<string>
}

/**
 * It renders within the web-portal in the root of the app
 */
export const Modal: React.FC<ModalProps> = ({
  children,
  closeButtonLabel,
  hasCloseButton,
  height = { small: '100vh', medium: 'fit-content' },
  isDarkModal,
  isOpen,
  label = '',
  margin = { small: '0', medium: '10vh auto' },
  maxHeight,
  maxWidth,
  onCancel,
  padding,
  width = { small: '100vw', medium: '68rem' },
  ...props
}) => {
  const { spacing } = useTheme()
  const ref = useRef<Element | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#portal')

    // If it can't find portal element - create one and append to the body
    if (!ref.current) {
      const rootContainer = document.createElement('div')
      rootContainer.setAttribute('id', 'portal')
      document.body.appendChild(rootContainer)

      ref.current = rootContainer
    }

    setMounted(true)
  }, [])

  const defaultPadding: ModalProps['padding'] = {
    small: spacing.xLarge,
    medium: `${spacing.default} ${spacing.husky} ${spacing.husky};`,
  }

  return mounted && ref.current
    ? createPortal(
        <StyledModal
          $height={height}
          $isDarkModal={isDarkModal}
          $margin={margin}
          $maxHeight={maxHeight}
          $maxWidth={maxWidth}
          $padding={padding ?? defaultPadding}
          $width={width}
          aria-label={label}
          data-testid="modal"
          isOpen={isOpen}
          onDismiss={onCancel}
          {...props}
        >
          {hasCloseButton ? (
            <ModalCloseButton
              aria-label={closeButtonLabel}
              data-testid="ModalCloseButton"
              isDarkModal={isDarkModal}
              onClick={onCancel}
            />
          ) : null}

          {children}
        </StyledModal>,
        ref.current
      )
    : null
}
