import { theme, ThemeProps } from '@kijiji/theme'

export const buttonScale = {
  medium: {
    height: '4.8rem',
    padding: `1rem ${theme.spacing.large}`,
  },
  small: {
    height: theme.spacing.husky,
    padding: `0.9rem ${theme.spacing.default}`,
  },
  compact: {
    height: theme.spacing.husky,
    padding: `${theme.spacing.defaultSmall} 1.2rem`,
  },
}

type ButtonStyleVariation = {
  background: string
  border: string
  font: string
}

export const buttonColorVariation = ({ colors }: ThemeProps) => ({
  primary: {
    default: {
      background: colors.purple.primary,
      border: 'transparent',
      font: colors.white,
    },
    hover: {
      background: colors.purple.light1,
      border: 'transparent',
      font: colors.white,
    },
    focus: {
      background: colors.purple.dark1,
      border: 'transparent',
      font: colors.white,
    },
    loading: {
      background: colors.purple.primary,
      border: 'transparent',
      font: colors.white,
    },
    disabled: {
      background: colors.grey.light3,
      border: 'transparent',
      font: colors.grey.light2,
    },
  },
  primaryDark: {
    default: {
      background: colors.green.dark1,
      border: 'transparent',
      font: colors.white,
    },
    hover: {
      background: colors.green.primary,
      border: 'transparent',
      font: colors.white,
    },
    focus: {
      background: colors.green.dark2,
      border: 'transparent',
      font: colors.white,
    },
    loading: {
      background: colors.green.dark1,
      border: 'transparent',
      font: colors.white,
    },
    disabled: {
      background: colors.grey.light3,
      border: 'transparent',
      font: colors.grey.light2,
    },
  },
  primaryNoBorder: {
    default: {
      background: 'transparent',
      border: 'transparent',
      font: colors.purple.primary,
    },
    hover: {
      background: 'transparent',
      border: 'transparent',
      font: colors.purple.primary,
    },
    focus: {
      background: 'transparent',
      border: 'transparent',
      font: colors.grey.primary,
    },
    loading: {
      background: 'transparent',
      border: 'transparent',
      font: colors.purple.primary,
    },
    disabled: {
      background: 'transparent',
      border: 'transparent',
      font: colors.grey.light2,
    },
  },
  secondary: {
    default: {
      background: colors.white,
      border: colors.purple.light2,
      font: colors.purple.primary,
    },
    hover: {
      background: colors.purple.light5,
      border: colors.purple.light2,
      font: colors.purple.primary,
    },
    focus: {
      background: colors.purple.light4,
      border: colors.purple.light2,
      font: colors.grey.primary,
    },
    loading: {
      background: colors.white,
      border: colors.purple.light2,
      font: colors.purple.primary,
    },
    disabled: {
      background: colors.grey.light4,
      border: colors.grey.light3,
      font: colors.grey.light2,
    },
  },
  secondaryDark: {
    default: {
      background: 'transparent',
      border: colors.white,
      font: colors.white,
    },
    hover: {
      background: colors.white,
      border: colors.white,
      font: colors.purple.primary,
    },
    focus: {
      background: colors.purple.light4,
      border: colors.purple.light4,
      font: colors.grey.primary,
    },
    loading: {
      background: 'transparent',
      border: colors.white,
      font: colors.white,
    },
    disabled: {
      background: 'transparent',
      border: colors.grey.light3,
      font: colors.grey.light2,
    },
  },
  tertiary: {
    default: {
      background: 'transparent',
      border: 'transparent',
      font: colors.green.dark1,
    },
    hover: {
      background: 'transparent',
      border: 'transparent',
      font: colors.green.dark2,
    },
    focus: {
      background: 'transparent',
      border: 'transparent',
      font: colors.green.dark3,
    },
    loading: {
      background: 'transparent',
      border: 'transparent',
      font: colors.green.primary,
    },
    disabled: {
      background: 'transparent',
      border: 'transparent',
      font: colors.grey.light2,
    },
  },
  tonal: {
    default: {
      background: colors.white,
      border: colors.grey.light3,
      font: colors.grey.primary,
    },
    hover: {
      background: colors.purple.light5,
      border: colors.grey.light3,
      font: colors.purple.primary,
    },
    focus: {
      background: colors.purple.light5,
      border: colors.purple.primary,
      font: colors.purple.primary,
    },
    loading: {
      background: colors.white,
      border: colors.purple.light2,
      font: colors.purple.primary,
    },
    disabled: {
      background: colors.white,
      border: colors.grey.light3,
      font: colors.grey.light2,
    },
  },
})

export const styleFromButtonColorVariation = ({
  background,
  border,
  font,
}: ButtonStyleVariation) => `
  background-color: ${background};
  border: 0.1rem solid ${border};
  color: ${font};
`
