export type SpacingVariations =
  | 'mTop'
  | 'mBottom'
  | 'mRight'
  | 'mLeft'
  | 'm'
  | 'pTop'
  | 'pBottom'
  | 'pRight'
  | 'pLeft'
  | 'p'

export const spacingVariationToProperty: {
  [x in SpacingVariations]: string
} = {
  m: 'margin',
  mBottom: 'margin-bottom',
  mLeft: 'margin-left',
  mRight: 'margin-right',
  mTop: 'margin-top',
  p: 'padding',
  pBottom: 'padding-bottom',
  pLeft: 'padding-left',
  pRight: 'padding-right',
  pTop: 'padding-top',
}

export type SpacingCoreAttributes = {
  [x in SpacingVariations]?: string
}

const spacingVariationToValue = (
  variation: SpacingVariations,
  value?: string
) => {
  return value ? `${spacingVariationToProperty[variation]}: ${value};` : ''
}

export const spacing = (props?: SpacingCoreAttributes) => {
  if (!props) return ''

  return Object.keys(props).reduce((acc: string, curr: string) => {
    const key = curr as SpacingVariations

    if (!props[key]) return acc

    return `${acc} ${spacingVariationToValue(
      key as SpacingVariations,
      props[key]
    )}`
  }, '')
}
