import { CookieRegistry } from '@/constants/cookieRegistry'
import { deleteCookie } from '@/utils/cookies/deleteCookie'

const COOKIES_TO_DEDUPE = [CookieRegistry.USER_PREFERENCES]

export const cleanDomainDuplicatedCookies = () =>
  COOKIES_TO_DEDUPE.forEach((cookieName) => {
    const dupedCookies = document.cookie
      .split('; ')
      .filter((row) => row.startsWith(`${cookieName}=`))

    if (dupedCookies.length > 1) {
      deleteCookie(cookieName, '')
    }
  })
