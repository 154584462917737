import { type PAGE_TYPE } from '@/constants/pageTypes'
import { type ApiLocale, API_LOCALE } from '@/domain/locale'

export enum GTM_SRP_VIEW_TYPE {
  GALLERY = 'GAL',
  LIST = 'LST',
  MAP = 'MAP',
}

export type TransformPageProps = {
  /**
   * Additional data to add to the page data
   */
  additionalData?: {
    srpViewType?: GTM_SRP_VIEW_TYPE
  }
  /**
   * Users current locale (ApiLocale)
   *
   * @default: 'en'
   */
  apiLocale?: ApiLocale
  /**
   * Page type for Google Analytics.
   * Refer to https://wiki.es.ecg.tools/pages/viewpage.action?pageId=98439770
   */
  pageType: PAGE_TYPE
}

type TransformAdditionalDataReturn = {
  vt?: GTM_SRP_VIEW_TYPE
}

type TransformPageReturn = { p: { vt?: GTM_SRP_VIEW_TYPE; t: PAGE_TYPE; lng: string } }

const transformAdditionalData = ({
  additionalData,
}: Pick<TransformPageProps, 'additionalData'>): TransformAdditionalDataReturn => {
  if (!additionalData) {
    return {}
  }

  const { srpViewType } = additionalData

  return {
    ...(srpViewType ? { vt: srpViewType } : {}),
  }
}

/**
 * Transforms the `page` (`p`) portion of GA
 */
export const transformPage = ({
  additionalData,
  apiLocale = API_LOCALE.en_CA,
  pageType,
}: TransformPageProps): TransformPageReturn => {
  return {
    p: {
      t: pageType,
      lng: apiLocale,
      ...transformAdditionalData({ additionalData }),
    },
  }
}
