export const LoginBegin = 'LoginBegin'
export const LoginFail = 'LoginFail'
export const LoginSuccess = 'LoginSuccess'
export const LogoutSuccess = 'LogoutSuccess'

type GetLoginFailLabelInput = {
  errors?: Error | unknown
}
export const getLoginFailLabel = ({ errors }: GetLoginFailLabelInput) => {
  if (Array.isArray(errors) && errors.every((error) => typeof error === 'string')) {
    return `error=${errors.join(', ')}`
  }

  return ''
}
