import { type UserLocation } from '@kijiji/generated/graphql-types'
import { sendGTMEvent } from '@next/third-parties/google'
import merge from 'deepmerge'
import { type Session } from 'next-auth'

import { CookieRegistry } from '@/constants/cookieRegistry'
import { ALL_CANADA_LABEL_EN, ALL_CANADA_LOCATION_ID } from '@/constants/location'
import locationTree from '@/constants/location/locationTree.json'
import { type ApiLocale } from '@/domain/locale'
import { PLATFORM_VERSION } from '@/lib/ga/constants/ga'
import { type SearchPageDataLayer } from '@/lib/ga/hooks/useSrpTracking'
import { OptimizelyClient } from '@/lib/optimizely/OptimizelyClient'
import DataLayerTaskQueue from '@/lib/task-queue/DataLayerTaskQueue'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'
import { getHashedUserIdFromCookie } from '@/utils/cookies/getHashedUserIdFromCookie'

type LocationItemDataLayer = { id: number; n: string }
export type LocationDataLayer = {
  /** Current Location */
  c: LocationItemDataLayer
  /** L0 Location Path */
  l0: { id: number; n: string }
  /** L1 location Path */
  l1?: LocationItemDataLayer
  /** L2 location Path */
  l2?: LocationItemDataLayer
  /** L2 location Path */
  l3?: LocationItemDataLayer
}

const getLocationPath = (id: number, locale: ApiLocale): LocationItemDataLayer[] => {
  const paths: LocationItemDataLayer[] = []
  const location = locationTree.find((item) => item.id === id)

  if (!location || id === 0) return paths

  const currLocation = { id: location.id, n: location.name[locale] }

  paths.push(currLocation)
  return paths.concat(getLocationPath(location.parentId, locale))
}

/**
 * Returns base dataLayer fields that are present on all pages. Everything within
 * this file should be page agnostic (no page logic should exist within here).
 * Refer to https://wiki.es.ecg.tools/pages/viewpage.action?spaceKey=ANYL&title=Data+Layer for more info.
 */
export async function buildDataLayer({
  additionalData = {},
  apiLocale,
  location,
  session,
}: {
  additionalData?: Record<string, unknown>
  apiLocale: ApiLocale
  location: UserLocation
  session: Session | null
}) {
  const getDevice = () => {
    return {
      ua: window?.navigator.userAgent,
      ck: getCookieByKey(document.cookie, CookieRegistry.MACHINE_ID),
    }
  }

  const getUser = async () => {
    const isLoggedIn = !!session?.user
    const accountType = session?.user?.type
    const categoryData: Partial<SearchPageDataLayer> = additionalData
    const categoryId = categoryData?.c?.c?.id
    const sessionTestGroups = await OptimizelyClient.getGaSessionTestGroups(categoryId)

    return {
      li: isLoggedIn,
      tg: { stg: sessionTestGroups },
      ...(isLoggedIn
        ? { huid: getHashedUserIdFromCookie(), ...(accountType && { at: accountType }) }
        : {}),
    }
  }

  const getLocation = () => {
    const l: LocationDataLayer = {
      c: { id: location.id, n: `${location.name[apiLocale]}` },
      l0: { id: ALL_CANADA_LOCATION_ID, n: ALL_CANADA_LABEL_EN },
    }

    /** LOCATION PATH*/
    const paths = getLocationPath(location.id, apiLocale).reverse()

    const l1Location = paths[0]
    const l2Location = paths[1]
    const l3Location = paths[2]

    if (l1Location) l.l1 = { id: l1Location.id, n: `${l1Location.n}` }
    if (l2Location) l.l2 = { id: l2Location.id, n: `${l2Location.n}` }
    if (l3Location) l.l3 = { id: l3Location.id, n: `${l3Location.n}` }

    return l
  }

  const getPlatformVersion = () => {
    return {
      pl: PLATFORM_VERSION,
    }
  }

  const baseDataLayer = {
    event: 'page_meta',
    l: getLocation(),
    u: await getUser(),
    d: getDevice(),
    p: getPlatformVersion(),
  }

  return merge(baseDataLayer, additionalData)
}

const TaskQueue = new DataLayerTaskQueue()

export const clearDataLayer = () => {
  /**
   * We don't want to put this in the TaskQueue because the clear should always
   * be processed before the page_meta and VirtualPageView events are inserted,
   * or it will wipe out the metadata required for the subsequent events to be
   * useful, and when inserted into the TaskQueue, the reset, which clears the
   * DataLayer winds up getting inserted after those events.
   *
   * This technique for resetting the dataLayer is documented here:
   * https://developers.google.com/tag-platform/devguides/datalayer#reset
   */
  sendGTMEvent(function () {
    // @ts-expect-error `this` is used by the Google dataLayer scripts, and does not refer to a class that we have control over
    this.reset()
  })
  TaskQueue.setIsInitilialized(false)
}

/**
 * Separating out the priority from isInitialBuild since there
 * are multiple priority dataLayer events that need to be
 * inserted *prior* to other events, namely the page_meta
 * and VirtualPageView events.
 *
 * This way, events pushed to the data layer using this method,
 * prior to the TaskQueue being marked as initialized may be
 * flagged at priority 0 to get them sorted to the front of
 * the queue.
 */
export const pushToDataLayer = (
  data: Record<string, unknown> | (() => void),
  priority = 1,
  isInitialBuild = false
) => {
  if (typeof window === 'undefined') return

  /**
   * requestIdleCallback is not available in all browsers
   * but we can use setTimeout as a fallback
   * https://caniuse.com/?search=requestidlecallback
   *
   * setTimeout with 0ms delay adds the callback to the event loop
   * which unblocks the main thread and allows the browser to render
   *
   */
  const pushData =
    typeof requestIdleCallback !== 'undefined'
      ? () =>
          requestIdleCallback(() => {
            sendGTMEvent(data)
          })
      : () =>
          setTimeout(() => {
            sendGTMEvent(data)
          }, 0)

  if (!TaskQueue.isInitialized) {
    TaskQueue.addTask(pushData, priority)

    if (isInitialBuild) {
      TaskQueue.processQueue()
      TaskQueue.setIsInitilialized(true)
    }
  } else {
    pushData()
  }
}
