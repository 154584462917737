import React, { ReactNode, SVGProps } from 'react'

export type IconsSize = 'xxsm' | 'xsm' | 'default' | 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg'

export interface IconProps extends SVGProps<SVGSVGElement> {
  /**
   * Title used when the icon is non-decorative
   */
  title?: string
  /**
   * Width overrides the size prop
   */
  width?: number | string
  /**
   * Prefer using the size prop if we want icons to change size
   */
  size?: IconsSize
  /**
   * Will render a rounded background with color specified for the icon, with a default padding of 1.2rem.
   */
  backgroundColor?: string
  children?: ReactNode
}

export type IconsSizeOptions = { [key in IconsSize]: number }

const getSize = (size: IconsSize): number => {
  const sizes: IconsSizeOptions = {
    xxsm: 16,
    xsm: 20,
    default: 24,
    sm: 32,
    md: 48,
    lg: 96,
    xlg: 128,
    xxlg: 144,
  }
  return sizes[size]
}

/**
 * @description Icons wrapper
 *
 * @param {IconProps} props
 */
export const Icon: React.FC<IconProps> = ({
  size = 'sm',
  width = undefined,
  children,
  title,
  backgroundColor,
  ...otherProps
}) => {
  const isAriaHidden = otherProps['aria-hidden']
  const ComponentSize = width ? width : getSize(size)
  const role = title && !isAriaHidden ? 'img' : undefined
  const titleId = `icon-${Math.floor(Math.random() * 100)}`
  const focusable = isAriaHidden ? 'false' : undefined

  if (title && isAriaHidden) {
    console.error(
      `The Icon props title and isAriaHidden shouldn't be used at the same time for ${titleId}`
    )
  }

  const renderIcon = () => (
    <svg
      {...otherProps}
      aria-hidden={isAriaHidden || undefined}
      aria-labelledby={title && !isAriaHidden ? titleId : undefined}
      focusable={focusable}
      height={ComponentSize}
      role={role}
      width={ComponentSize}
    >
      {title && !isAriaHidden ? <title id={titleId}>{title}</title> : null}
      {children}
    </svg>
  )

  if (backgroundColor) {
    const iconRoundedStyle = {
      backgroundColor,
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'fit-content',
      height: 'fit-content',
      padding: '0.8rem',
    }

    return (
      <>
        <div style={iconRoundedStyle}>{renderIcon()}</div>
      </>
    )
  }

  return renderIcon()
}
