import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgVisibility: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#373373">
        <path d="M17.796 8.654c-1.1-.993-4.588-3.817-8.68-3.817-4.094 0-7.568 2.838-8.654 3.822a.44.44 0 000 .673c1.08.992 4.522 3.821 8.655 3.821 4.132 0 7.59-2.821 8.683-3.816a.443.443 0 00-.004-.683zm-8.68 3.983c-3.917 0-7.25-2.725-8.253-3.642 1.013-.912 4.372-3.643 8.257-3.643 3.886 0 7.264 2.732 8.286 3.643-1.008.91-4.37 3.642-8.29 3.642z" />
        <path d="M7.085 8.995a2.04 2.04 0 104.08 0 2.04 2.04 0 00-4.08 0zm2.04-1.519a1.52 1.52 0 11-.005 0zM9.143 3.108a.258.258 0 00.258-.258V.378a.258.258 0 10-.516 0V2.85a.258.258 0 00.258.258zM9.143 14.882a.258.258 0 00-.258.258v2.472a.258.258 0 00.516 0V15.14a.258.258 0 00-.258-.258zM3.893 4.11a.258.258 0 00.36-.36L2.509 1.997a.258.258 0 00-.36.36zM14.21 4.186a.256.256 0 00.183-.076l1.748-1.748a.259.259 0 00.032-.393.259.259 0 00-.392.033l-1.753 1.743a.257.257 0 00.183.44zM3.893 13.88L2.145 15.63a.258.258 0 10.36.36l1.748-1.749a.258.258 0 00-.36-.36zM14.393 13.88a.258.258 0 00-.36.36l1.748 1.749a.258.258 0 10.36-.36z" />
      </g>
    </Icon>
  )
}
export default SvgVisibility
