import dynamic from 'next/dynamic'
import { PropsWithChildren, ReactNode } from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'

import { StyledAs } from '../..'
import {
  AccordionDescription,
  RotatingChevronContainer,
  StyledAccordionButton,
  StyledAccordionItem,
  StyledAccordionPanel,
} from './styled'
import { useIsExpandedItem } from './useIsExpandedItem'

const RotatingChevron = dynamic(
  () => import('../rotating-chevron').then((mod) => mod.RotatingChevron),
  { ssr: false }
)

export type AccordionItemProps = PropsWithChildren<{
  /**
   * Description placed below the title
   */
  description?: string | ReactNode
  /**
   * Determines whether the accordion item is disabled
   */
  isDisabled?: boolean
  /**
   * Accordion title
   */
  title?: string
  /**
   * Tag to use for title
   */
  titleTag?: StyledAs
  /**
   * Optional onClick callback function
   */
  onClick?: (id: string) => void
  /**
   * Accordion item ID
   */
  id: string
  /**
   * Determines whether the title should be emphasized
   */
  hasTitleEmphasis?: boolean
  /**
   * An optional callback function that receives the accordion item expanded value and returns a React Node to be rendered instead of the default accordion button content
   */
  renderAlternateButtonContent?: (expanded: boolean) => ReactNode
}>

export const AccordionItem = ({
  children,
  description,
  hasTitleEmphasis = false,
  isDisabled = false,
  title,
  titleTag,
  id,
  renderAlternateButtonContent = undefined,
  onClick,
}: AccordionItemProps) => {
  const { colors } = useTheme()
  const { isExpanded, handleRef } = useIsExpandedItem()

  const getDisabledProps = (disabled?: boolean) => {
    return disabled ? { 'aria-expanded': false, 'aria-disabled': true } : {}
  }

  return (
    <StyledAccordionItem
      key={id}
      value={id}
      disabled={isDisabled}
      data-accordion-id={id}
      ref={handleRef}
    >
      <StyledAccordionButton
        aria-label={title}
        aria-describedby={`accordion-item-description-${id}`}
        $hasDescription={!!description}
        onClick={() => onClick?.(id)}
        {...getDisabledProps(isDisabled)}
      >
        {renderAlternateButtonContent ? (
          renderAlternateButtonContent(!!isExpanded)
        ) : (
          <>
            <div>
              <BodyText
                as={titleTag}
                color={colors.grey.primary}
                size={hasTitleEmphasis ? 'large' : 'medium'}
                weight={hasTitleEmphasis ? 'medium' : 'regular'}
              >
                {title}
              </BodyText>
              <AccordionDescription
                id={`accordion-item-description-${id}`}
                className="accordion-description"
              >
                {description}
              </AccordionDescription>
            </div>

            <RotatingChevronContainer>
              <RotatingChevron isOpen={!!isExpanded && !isDisabled} />
            </RotatingChevronContainer>
          </>
        )}
      </StyledAccordionButton>

      <StyledAccordionPanel aria-describedby={id}>
        {children}
      </StyledAccordionPanel>
    </StyledAccordionItem>
  )
}
