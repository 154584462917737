import { type UserLocation } from '@kijiji/generated/graphql-types'

import { LatestSearchCookieRegistry } from '@/constants/cookieRegistry'
import { type LatestSearchCookie } from '@/utils/cookies/latestSearchCookie'

/**
 * Extracts and normalizes the location values from the latest search cookie
 *
 * @param latestSearch the latest search cookie values in object format
 * @returns
 */
export const convertLocationToLatestSearchCookie = (location: UserLocation): LatestSearchCookie => {
  let lsCookie: LatestSearchCookie = {
    [LatestSearchCookieRegistry.LOCATION_ID]: location.id.toString(),
    [LatestSearchCookieRegistry.ADDRESS]: '',
    [LatestSearchCookieRegistry.LATITUDE]: '',
    [LatestSearchCookieRegistry.LONGITUDE]: '',
    [LatestSearchCookieRegistry.RADIUS]: '',
  }

  if (!location.isRegion && location.area) {
    const { latitude, longitude, radius, address } = location.area

    lsCookie = {
      ...lsCookie,
      [LatestSearchCookieRegistry.ADDRESS]: address ?? '',
      [LatestSearchCookieRegistry.LATITUDE]: latitude.toString(),
      [LatestSearchCookieRegistry.LONGITUDE]: longitude.toString(),
      [LatestSearchCookieRegistry.RADIUS]: radius.toString(),
    }
  }

  return lsCookie
}
