import styled from 'styled-components'

import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'
import { RequiredPickMap } from '@/ui/typings/helpers'

import { Label } from '../label'
import { ToggleProps } from './Toggle'

const TOGGLE_DIMENSIONS = {
  diameter: 'calc(2.4rem - 0.4rem)',
  gap: '0.2rem',
  height: '2.4rem',
  width: '4rem',
}

type ToggleButtonProps = RequiredPickMap<
  ToggleProps,
  'labelPosition' | 'bottom'
> & {
  isFullWidth?: boolean
}

export const ToggleButton = styled(Label)<ToggleButtonProps>(
  ({ theme, $labelPosition, $bottom = 0, isFullWidth = true }) => `
  display: flex;
  flex-direction: ${$labelPosition === 'left' ? 'row' : 'row-reverse'};
  justify-content: space-between;
  margin-bottom: ${$bottom};
  text-align: left;

  width: ${isFullWidth ? '100%' : 'auto'};
  & > span {
    margin-left: ${$labelPosition === 'left' ? theme.spacing.default : '0'};
    margin-right: ${$labelPosition === 'right' ? theme.spacing.default : '0'};
  }

  &[aria-pressed=false] > span {
    background-color: ${theme.colors.grey.light2};
  }

  &[aria-pressed=true] > span {
    background-color: ${theme.colors.purple.primary};

    &::before {
      transform: translateX(calc(${TOGGLE_DIMENSIONS.width} - ${
        TOGGLE_DIMENSIONS.diameter
      } - ${TOGGLE_DIMENSIONS.gap} * 2));
    }
  }
`
)

export const ToggleComponent = styled.span(
  ({ theme }) => `
  ${TRANSITION_STYLE}
  border-radius: ${theme.spacing.husky};
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  height: ${TOGGLE_DIMENSIONS.height};
  position: relative;
  width: ${TOGGLE_DIMENSIONS.width};

  &::before {
    ${TRANSITION_STYLE}
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.rounded};
    content: "";
    height: ${TOGGLE_DIMENSIONS.diameter};
    left: ${TOGGLE_DIMENSIONS.gap};
    position: absolute;
    top: ${TOGGLE_DIMENSIONS.gap};
    transform: translateX(0);
    width: ${TOGGLE_DIMENSIONS.diameter};
  }
  `
)
