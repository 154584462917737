import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useEffect, useRef } from 'react'

import { hasSystemMsgQueryParam } from '@/domain/hasSystemMsgQueryParam'
import { hasUserFeedbackQueryParam } from '@/domain/hasUserFeedbackQueryParam'
import { usePrevious } from '@/hooks/usePrevious'

export type UseTrackOnceOnLoadProps = {
  /* The callback function that is triggered on unique page load to track an event
  or push page meta data to the dataLayer  */
  triggerTracking: () => void
  /* A custom boolean parameter that determines whether tracking should be triggered
  at the parent component level  */
  shouldTrack?: boolean
  /**
   * Determines whether or not we should clear the dataLayer. We may not want to clear the
   * dataLayer in some instances. For example, when manually calling the `reportPageview` function (ex. when changing data related to a filter).
   */
  shouldClearDataLayer?: boolean
}

export const useTrackOnceOnLoad = ({
  triggerTracking,
  shouldTrack = true,
  shouldClearDataLayer = true,
}: UseTrackOnceOnLoadProps) => {
  const { status } = useSession()
  const { asPath, query } = useRouter()
  const previousPath = usePrevious(asPath)
  const isFirstRender = useRef<boolean>(true)

  useEffect(() => {
    if (
      shouldTrack &&
      status !== 'loading' &&
      !hasSystemMsgQueryParam(query) &&
      !hasUserFeedbackQueryParam(query)
    ) {
      //Is this the initial page load, or the first time we've
      //visited this URL (in the case of shallow navigation)
      if (isFirstRender.current || previousPath !== asPath) {
        isFirstRender.current = false
        triggerTracking()
      }
    }
  }, [asPath, previousPath, query, shouldClearDataLayer, status, triggerTracking, shouldTrack])
}
