import { type UserLocation } from '@kijiji/generated/graphql-types'
import { type ReactNode } from 'react'

import { type RouteLocale, ROUTE_LOCALE } from '@/domain/locale'

export const PROVINCES_ABBREVIATION: { [x: number]: string } = {
  9001: 'QC',
  9002: 'NS',
  9003: 'AB',
  9004: 'ON',
  9005: 'NB',
  9006: 'MB',
  9007: 'BC',
  9008: 'NL',
  9009: 'SK',
  9011: 'PE',
}

export const ALL_CANADA_LOCATION_ID = 0
export const ALL_CANADA_LABEL_EN = 'Canada'
export const ALL_CANADA_LABEL_FR = 'Canada'

export const ALBERTA_LOCATION_ID = 9003
export const CALGARY_LABEL_EN = 'Calgary'
export const CALGARY_LOCATION_ID = 1700199
export const GTA_LOCATION_ID = 1700272
export const HAMILTON_LOCATION_ID = 80014
export const KITCHENER_WATERLOO_LOCATION_ID = 1700212
export const MANITOBA_LOCATION_ID = 9006
export const MARKHAM_YORK_LOCATION_ID = 1700274
export const MISSISSAUGA_PEEL_LOCATION_ID = 1700276
export const NEWFOUNDLAND_LOCATION_ID = 9008
export const OAKVILLE_HALTON_LOCATION_ID = 1700277
export const ONTARIO_LOCATION_ID = 9004
export const OSHAWA_DURHAM_LOCATION_ID = 1700275
export const PRICE_EDWARD_ISLAND_LOCATION_ID = 9011
export const QUEBEC_LOCATION_ID = 9001
export const QUEBEC_PROVINCE_LABEL_EN = 'Quebec'
export const TORONTO_LOCATION_ID = 1700273
const NUNAVUT_LOCATION_ID = 1700105

// RADIUS
export const MINIMUM_RADIUS_VALUE = 1
export const MAXIMUM_RADIUS_VALUE = 1000
export const RADIUS_SLIDER_HALF_POINT_KMS = 50
export const DEFAULT_RADIUS_AUTOS = 150
export const DEFAULT_CLOSE_TO_ME_RADIUS = 5

export const DEFAULT_USER_LOCATION: UserLocation = {
  id: ALL_CANADA_LOCATION_ID,
  isRegion: true,
  name: { en_CA: ALL_CANADA_LABEL_EN, fr_CA: ALL_CANADA_LABEL_FR },
  area: null,
}

// SEARCH LOCATION MODAL
export const LOCATION_ERROR_TYPE = {
  GOOGLE_LOCATION_ERROR: 'GOOGLE_LOCATION_ERROR',
  LIVE_LOCATION_ERROR: 'LIVE_LOCATION_ERROR',
  LOCATION_NOT_SELECTED_ERROR: 'LOCATION_NOT_SELECTED_ERROR',
}

export type LocationErrorType = (typeof LOCATION_ERROR_TYPE)[keyof typeof LOCATION_ERROR_TYPE]

export const LOCATION_OPTION_TYPE = {
  GOOGLE_LOCATION: 'GOOGLE_LOCATION',
  LIVE_LOCATION: 'LIVE_LOCATION',
  NEAR_LIVE_LOCATION: 'NEAR_LIVE_LOCATION',
  RECENT_SEARCH_SUGGESTIONS: 'RECENT_SEARCH_SUGGESTIONS',
} as const
export type LocationOptionType = (typeof LOCATION_OPTION_TYPE)[keyof typeof LOCATION_OPTION_TYPE]

/** This enum is only used in e2e tests, and should be maintained alongside LOCATION_OPTION_TYPE */
export enum LocationOptionEnum {
  GOOGLE_LOCATION = 'GOOGLE_LOCATION',
  LIVE_LOCATION = 'LIVE_LOCATION',
  NEAR_LIVE_LOCATION = 'NEAR_LIVE_LOCATION',
  RECENT_SEARCH_SUGGESTIONS = 'RECENT_SEARCH_SUGGESTIONS',
}

export type LocationOption = {
  /**
   * Type of location option
   */
  type: LocationOptionType
  /**
   * Location address label
   */
  address: string
  /**
   * The suggestion ID. Empty for the live location, the Google placeId for the generated suggestions and the array index for the saved recent search locations.
   */
  suggestionId: string
  /**
   * Location option icon
   */
  icon: ReactNode
  /**
   * An optional label for displaying a badge on the location option
   */
  badge?: string
}

/**
 * A static version of the homepage is cached at build time for these location IDs.
 */
export const LOCATION_ID_LOCALE_PAIRS_FOR_HOMEPAGE_SSG: Array<[RouteLocale, number]> = [
  [ROUTE_LOCALE.en, NUNAVUT_LOCATION_ID],
  [ROUTE_LOCALE.fr, NUNAVUT_LOCATION_ID],
]
