import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCustomize: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.637 8.713l-3.938-3.92a.038.038 0 01-.01-.024c0-.01.003-.018.01-.025l1.964-1.958a.038.038 0 01.038-.008.031.031 0 01.011.008l3.84 3.828.36-.36-3.84-3.821a.61.61 0 00-.068-.056.254.254 0 00-.096-.085L3.555 1.095 1.539.147a.353.353 0 00-.504.256.35.35 0 00.032.213l.948 2.023 1.231 2.403s0 .007.009.012a.581.581 0 00.079.103l3.938 3.915zM1.722.8l1.61.75 1.883.957-1.78 1.773-.96-1.866z"
        fill="#373373"
      />
      <path
        d="M10.358 11.432l2.025-2.001.367-.359 1.15-1.134c1.335.22 2.548-.055 3.428-.781s1.362-1.84 1.362-3.137a3.86 3.86 0 00-.348-1.6.257.257 0 00-.312-.14.259.259 0 00-.106.063l-2.031 2.029h-1.46V2.917L16.465.891a.257.257 0 00-.076-.416 3.89 3.89 0 00-1.606-.347c-1.63 0-2.576.829-3.084 1.524-.72.989-1.016 2.38-.74 3.443l-1.1 1.097-.36.358-2.02 2.013-.36.358-.788.786a4.16 4.16 0 00-4.613 1.545 4.127 4.127 0 00.034 4.852 4.159 4.159 0 006.702-.023 4.127 4.127 0 00.665-3.42l.877-.866zm-1.78 1.22a3.585 3.585 0 01-1.427 3.954 3.616 3.616 0 01-4.214-.095 3.59 3.59 0 01.068-5.79 3.615 3.615 0 013.308-.472.258.258 0 00.268-.06l4.853-4.838a.257.257 0 00.063-.261c-.254-.787-.12-2.116.62-3.136.437-.598 1.256-1.316 2.668-1.316.353 0 .703.056 1.038.164l-1.83 1.827a.259.259 0 00-.077.182v1.818a.257.257 0 00.258.256H16a.262.262 0 00.182-.075l1.828-1.822c.109.334.164.682.164 1.033 0 1.142-.418 2.114-1.176 2.741-.787.65-1.902.88-3.138.648a.255.255 0 00-.23.07l-4.984 4.919a.256.256 0 00-.067.253z"
        fill="#363373"
      />
      <g fill="#373373">
        <path d="M5.159 11.337a2.32 2.32 0 00-2.147 1.418 2.302 2.302 0 00.496 2.52 2.319 2.319 0 003.957-1.63c.001-.61-.241-1.197-.674-1.63a2.317 2.317 0 00-1.632-.678zm0 4.103a1.804 1.804 0 01-1.67-1.101 1.79 1.79 0 01.384-1.959 1.802 1.802 0 013.076 1.265c0 .475-.188.93-.523 1.266-.336.336-.791.526-1.267.529zM16.61 12.923L12.64 8.96l-.368.359 3.49 3.48-2.012 2.014-3.512-3.501-.367.359 3.514 3.501 2.334 2.327a.721.721 0 001.014 0l1.725-1.729a.717.717 0 000-1.01zm1.484 2.49l-1.73 1.723a.202.202 0 01-.284 0l-1.848-1.843 2.014-2.008 1.848 1.843a.2.2 0 010 .283z" />
      </g>
    </Icon>
  )
}
export default SvgCustomize
